import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormControl from "../../../components/FormUI/FormControl";

// Components
import LightInfoBox from "../../../components/LightInfoBox";

function Step_2(props) {
  const monthOptions = [
    { key: "", value: "" },
    { key: "Januar", value: "1" },
    { key: "Februar", value: "2" },
    { key: "März", value: "3" },
    { key: "April", value: "4" },
    { key: "Mai", value: "5" },
    { key: "Juni", value: "6" },
    { key: "Juli", value: "7" },
    { key: "August", value: "8" },
    { key: "September", value: "9" },
    { key: "Oktober", value: "10" },
    { key: "November", value: "11" },
    { key: "Dezember", value: "12" }
  ];

  const initialValues = {
    openingHours: {
      from: "",
      till: ""
    },
    rooms: "",
    overnights: "",
    stars: "",
    area: {
      usable: {
        total: "",
        indoor: "",
        outdoor: ""
      },
      heatable: ""
    }
  };

  const validationSchema = Yup.object({
    openingHours: Yup.object({
      from: Yup.string().required("Pflichtfeld").nullable(),
      till: Yup.string().required("Pflichtfeld").nullable()
    }),
    rooms: Yup.number().typeError("Keine gültige Zahl").integer().min(1, "Mindestens ein Zimmer").required("Pflichtfeld"),
    overnights: Yup.number().typeError("Keine gültige Zahl").integer().min(1, "Mindestens eine Übernachtung").required("Pflichtfeld"),
    stars: Yup.number().typeError("Keine gültige Zahl").integer().min(0, "Zahl muss positiv sein").max(5, "Maximal fünf Sterne"),
    area: Yup.object({
      usable: Yup.object({
        total: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").min(1, "Mindestens ein Quadratmeter").required("Pflichtfeld"),
        indoor: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").min(0),
        outdoor: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").min(0)
      }),
      heatable: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").min(1, "Mindestens ein Quadratmeter").required("Pflichtfeld")
    })
  });

  function onSubmit(values) {
    const refactorData = { ...values };

    refactorData.area.usable.indoor = refactorData.area.usable.indoor === "" ? 0 : refactorData.area.usable.indoor;
    refactorData.area.usable.outdoor = refactorData.area.usable.outdoor === "" ? 0 : refactorData.area.usable.outdoor;

    props.storeValues(refactorData);
    nextHandler();
  }

  function nextHandler() {
    props.nextStepHandler();
  }

  function prevHandler(e) {
    e.preventDefault();
    props.prevStepHandler();
  }

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Betriebliche Informationen</h3>
            <p className="mt-4 text-xs text-gray-600">* Pflichtfelder</p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Formik initialValues={props.formData || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {formik => (
              <Form>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-4 gap-6">
                      <div className="col-span-4">
                        <p className="mb-1 block text-sm font-medium text-gray-700">Öffnungszeiten *</p>
                        <div className="grid grid-cols-4 gap-6">
                          <div className="col-span-4 sm:col-span-2">
                            <FormControl control="select" name="openingHours.from" before="Von" options={monthOptions} />
                          </div>
                          <div className="col-span-4 sm:col-span-2">
                            <FormControl control="select" name="openingHours.till" before="Bis" options={monthOptions} />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-4 -mt-2">
                        <LightInfoBox info="Bei Ganzjahresbetrieb bitte <strong>Januar</strong> bis <strong>Dezember</strong> auswählen" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="input" label="Anzahl Zimmer *" name="rooms" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="input" label="Übernachtungen pro Jahr *" name="overnights" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="input" label="Sterne" name="stars" />
                      </div>
                      <div className="col-span-4 sm:col-span-2"></div>
                      <div className="col-span-4">
                        <FormControl control="input" label="Nutzfläche *" name="area.usable.total" after="m<sup>2</sup>" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="input" label="davon innen *" name="area.usable.indoor" after="m<sup>2</sup>" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="input" label="davon außen *" name="area.usable.outdoor" after="m<sup>2</sup>" />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="input" label="Beheizbare Fläche *" name="area.heatable" after="m<sup>2</sup>" />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex space-x-2 justify-between md:justify-end">
                    {props.stepNumber > 0 && (
                      <button onClick={prevHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Zurück
                      </button>
                    )}

                    <button disabled={!formik.isValid} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20">
                      Weiter
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Step_2;
