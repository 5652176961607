import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { DottedSpinner } from "../Icons/CustomIcons";

// Components
import ToggleSwitch from "../FormUI/ToggleSwitch";

function DashboardCompanyItem(props) {
  const { _id, companyName, firstname, lastname, email, phone, blocked } = props.company;
  const { activateFn, deactivateFn } = props;

  const [isLoadingActivate, setIsLoadingActivate] = useState(false);
  const [isLoadingDeactivate, setIsLoadingDeactivate] = useState(false);

  useEffect(() => {
    if (isLoadingActivate || isLoadingDeactivate) {
      setIsLoadingActivate(false);
      setIsLoadingDeactivate(false);
    }
  }, [blocked]);

  function activate() {
    setIsLoadingActivate(true);
    activateFn(_id, companyName);
  }

  function deactivate() {
    setIsLoadingDeactivate(true);
    deactivateFn(_id, companyName);
  }

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{companyName}</td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {firstname} {lastname}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <p>{email}</p>
        <p>{phone}</p>
      </td>
      <td className="whitespace-nowrap ">
        <ToggleSwitch deactivate={deactivate} activate={activate} isActive={!blocked} />
      </td>
    </tr>
  );
}

export default DashboardCompanyItem;
