import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PaperClipIcon, CheckIcon, XIcon } from "@heroicons/react/solid";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import axios from "axios";

// Store
import * as uiActions from "../../../store/actions/ui";

// Components
import Page from "../../../components/Page";
import { DottedSpinner } from "../../../components/Icons/CustomIcons";

function OfferRegistry(props) {
  const [request, setRequest] = useState({});
  const [requestDate, setRequestDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [isLoadingDecline, setIsLoadingDecline] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const user = useSelector(state => state.auth.user);
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.post("/getOfferRequests/" + id, { token: user.token }, { cancelToken: fetchRequest.token });
        setRequest(response.data);
        setRequestDate(new Date(response.data.createdAt));
        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
          dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  async function confirmOffer() {
    setIsLoadingConfirm(true);
    try {
      const response = await axios.patch("/confirmOffer/" + id, { token: user.token });
      setIsLoadingConfirm(false);
      dispatch(uiActions.flashMessageDashboard("success", request.offerName, "wurde erfolgreich freigeschaltet"));
    } catch (e) {
      console.log("There was an problem");
      setIsLoadingConfirm(true);
      dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
    }
    navigate("/dashboard/requests-offer", { replace: true });
  }

  async function declineOffer() {
    setIsLoadingDecline(true);
    try {
      const response = await axios.patch("/declineOffer/" + id, { token: user.token });
      setIsLoadingDecline(false);
      dispatch(uiActions.flashMessageDashboard("success", request.offerName, " wurde erfolgreich abgelehnt"));
    } catch (e) {
      console.log("There was an problem");
      setIsLoadingDecline(false);
      dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
    }
    navigate("/dashboard/requests-offer", { replace: true });
  }

  async function handleDownload(filename) {
    setIsLoadingDownload(true);
    try {
      const response = await axios.get("/download/" + filename, {
        headers: {
          Authorization: user.token
        },
        responseType: "blob"
      });
      setIsLoadingDownload(false);

      const file = new Blob([response.data], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      const pdfWindow = window.open();

      pdfWindow.location.href = fileURL;
    } catch (e) {
      console.log("There was an problem");
      setIsLoadingDownload(false);
      dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
    }
    navigate("/dashboard/requests-offer", { replace: true });
  }

  return (
    <Page dashboard title="Registrierungen">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <DottedSpinner className="h-10 w-10 text-gray-500 animate-spin" />
        </div>
      ) : (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Angebotsanfrage</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">vom {requestDate.getDate() + "." + (parseInt(requestDate.getMonth()) + 1).toString() + "." + requestDate.getUTCFullYear()} </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Firma</dt>
                <dd className="mt-1 text-sm font-medium text-gray-900">{request.companyName}</dd>
                <dd className="mt-1 text-xs text-gray-700">{request.street}</dd>
                <dd className="text-xs text-gray-700">{request.postalCode}</dd>
                <dd className="text-xs text-gray-700">{request.companyEmail}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Ansprechpartner</dt>
                <dd className="mt-1 text-sm text-gray-900">{request.firstname + " " + request.lastname}</dd>
                <dd className="mt-1 text-xs text-gray-700">Tel.: {request.phone}</dd>
                <dd className="text-xs text-gray-700">Fax: {request.fax}</dd>
                <dd className="text-xs text-gray-700">{request.contactEmail}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Angebotsname</dt>
                <dd className="mt-1 text-sm text-gray-900">{request.offerName}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Webseite</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <a href={"http://" + request.socialMedia.web} target="_blank" className="text-indigo-600">
                    {request.socialMedia.web}
                  </a>
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Berechnungsergebnis</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {request.calculatedCo2.replace(".", ",")}{" "}
                  <span className="text-xs">
                    kg/CO<sub>2</sub>
                  </span>
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Beschreibung</dt>
                <ReactMarkdown className="prose prose-sm border-2 max-w-full mt-4 p-4 rounded-lg" children={request.description} remarkPlugins={[remarkGfm]} />
              </div>
              {request.hasCo2Certificate || request.hasBioCertificate ? (
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">Zertifikate</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {request.hasCo2Certificate && (
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span className="ml-2 flex-1 w-0 truncate">
                              CO<sub>2</sub>-Zertifikat
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <button type="button" onClick={() => handleDownload(request.co2CertificateFile)} className="font-medium text-indigo-600 hover:text-indigo-500">
                              Download
                            </button>
                          </div>
                        </li>
                      )}
                      {request.hasBioCertificate && (
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span className="ml-2 flex-1 w-0 truncate">Bio-Zertifikat</span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <button type="button" onClick={() => handleDownload(request.bioCertificateFile)} className="font-medium text-indigo-600 hover:text-indigo-500">
                              Download
                            </button>
                          </div>
                        </li>
                      )}
                    </ul>
                  </dd>
                </div>
              ) : null}
            </dl>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex space-x-2 justify-between md:justify-end">
            <button disabled={isLoadingConfirm || isLoadingConfirm} onClick={declineOffer} type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
              {isLoadingDecline ? <DottedSpinner className="h-5 w-5 sm:mr-2 animate-spin" /> : <XIcon className="h-5 w-5 sm:mr-2" />}
              <span className="hidden sm:block">
                <span className="sr-only">{request.offerName}, </span>Ablehnen
              </span>
            </button>

            <button disabled={isLoadingConfirm || isLoadingConfirm} onClick={confirmOffer} type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 disabled:cursor-not-allowed disabled:opacity-20">
              {isLoadingConfirm ? <DottedSpinner className="h-5 w-5 sm:mr-2 animate-spin" /> : <CheckIcon className="h-5 w-5 sm:mr-2" />}
              <span className="hidden sm:block">
                <span className="sr-only">{request.offerName}, </span>Zulassen
              </span>
            </button>
          </div>
        </div>
      )}
    </Page>
  );
}

export default OfferRegistry;
