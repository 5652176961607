import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { offerStatusTypes } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// Store
import * as uiActions from "../../store/actions/ui";

// Components
import Page from "../../components/Page";
import DashboardOffersItem from "../../components/Dashboard/DashboardOffersItem";
import { DottedSpinner } from "../../components/Icons/CustomIcons";

function DashboardOffers(props) {
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.post("/getOwnOffers", { token: user.token }, { cancelToken: fetchRequest.token });
        setOffers(response.data);
        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
          setIsLoading(false);
          dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  return (
    <Page dashboard title="Angebote">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Angebote</h1>
            <p className="mt-2 text-sm text-gray-700">Hier finden Sie Ihre gelisteten Angebote</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link to="add" className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
              Neues Angebot
            </Link>
          </div>
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {offers.length === 0 ? (
            <div className="text-center p-8 text-gray-900 font-medium bg-white flex items-center justify-center">{isLoading ? <DottedSpinner className="h-10 w-10 text-gray-500 animate-spin" /> : "Sie haben noch kein Angebot erstellt"}</div>
          ) : (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Name
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                    CO<sub>2</sub> Emission
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {offers.map(offer => (
                  <DashboardOffersItem key={offer._id} offer={offer} />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Page>
  );
}

export default DashboardOffers;
