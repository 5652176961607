import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DottedSpinner } from "../components/Icons/CustomIcons";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";

// Store
import * as authActions from "../store/actions/auth";
import * as uiActions from "../store/actions/ui";

// Components
import LoginEmail from "../components/FormUI/LoginEmail";
import LoginPassword from "../components/FormUI/LoginPassword";

function Login(props) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: ""
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Keine gültige Email").required("Pflichtfeld"),
    password: Yup.string().required("Pflichtfeld")
  });

  function onSubmit(values) {
    setIsLoading(true);
    login(values);
  }

  async function login(values) {
    try {
      const response = await axios.post(`/login`, values);
      if (response.data !== "not active" && response.data) {
        dispatch(authActions.login(response.data));
        dispatch(uiActions.flashMessageShop("success", "Anmeldung erfolgreich"));
      } else if (response.data === "not active") {
        dispatch(uiActions.flashMessageShop("alert", "Anmeldung fehlgeschlagen", "Ihr Account wurde noch nicht aktiviert"));
      } else if (!response.data) {
        console.log("There was an error");
        dispatch(uiActions.flashMessageShop("alert", "Anmeldung fehlgeschlagen"));
      }
    } catch (e) {
      console.log("There was an error");
      dispatch(uiActions.flashMessageShop("alert", "Anmeldung fehlgeschlagen"));
    }

    navigate("/", { replace: true });
  }

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount>
            {formik => (
              <Form className="space-y-6">
                <div>
                  <LoginEmail name="email" />
                </div>
                <div>
                  <LoginPassword name="password" />
                </div>

                <div>
                  <button disabled={!formik.isValid || isLoading} type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-25 disabled:cursor-not-allowed">
                    {isLoading ? <DottedSpinner className="w-5 h-5 text-white animate-spin" /> : "Anmelden"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="flex items-center justify-center mt-8">
        <div className="flex items-center">
          <p htmlFor="privacy" className="block text-xs text-gray-500">
            Noch kein Konto?{" "}
            <Link to="/register" className="text-indigo-600 text-xs">
              Hier geht es zur Registriegung
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
