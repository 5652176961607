import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// Store
import * as uiActions from "../../../store/actions/ui";

// Components
import Page from "../../../components/Page";
import DashboardCompanyItem from "../../../components/Dashboard/DashboardCompanyItem";
import { DottedSpinner } from "../../../components/Icons/CustomIcons";

function Companies(props) {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.post("/getCompanies", { token: user.token }, { cancelToken: fetchRequest.token });
        setCompanies(response.data);
        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
          setIsLoading(false);
          dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  async function activateCompany(id, name) {
    try {
      const response = await axios.patch("/activateBusiness/" + id, { token: user.token });
      dispatch(uiActions.flashMessageDashboard("success", name, "wurde erfolgreich aktiviert"));
    } catch (e) {
      console.log("There was an problem");
      dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
    }
  }

  // function activateHandler(id) {
  //   const newCompanies = companies.filter(r => r._id !== id);
  //   setCompanies(newCompanies);
  // }

  // function blockHandler(id) {
  //   const newCompanies = companies.filter(r => r._id !== id);
  //   setCompanies(newCompanies);
  // }

  async function deactivateCompany(id, name) {
    try {
      const response = await axios.patch("/deactivateBusiness/" + id, { token: user.token });
      dispatch(uiActions.flashMessageDashboard("success", name, " wurde erfolgreich deaktiviert"));
    } catch (e) {
      console.log("There was an problem");
      dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
    }
  }

  return (
    <Page dashboard title="Unternehmen">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Unternehmen</h1>
            <p className="mt-2 text-sm text-gray-700">Hier finden Sie alle registrierten Unternehmen</p>
          </div>
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {companies.length === 0 ? (
            <div className="text-center p-8 text-gray-900 font-medium bg-white flex items-center justify-center">{isLoading ? <DottedSpinner className="h-10 w-10 text-gray-500 animate-spin" /> : "Keine registrierten Unternehmen"}</div>
          ) : (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Firma
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                    Ansprechpartner
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Kontakt
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Controls</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {companies.map(company => (
                  <DashboardCompanyItem key={company._id} company={company} activateFn={activateCompany} deactivateFn={deactivateCompany} />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Page>
  );
}

export default Companies;
