import React from "react";
import { Link } from "react-router-dom";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import axios from "axios";

// Components
import HotelStars from "./HotelStars";
import FeatureBadge from "./FeatureBadge";

function OfferGridItem(props) {
  const { _id, offerName, stars, shortDescription, coverImg, postalCode, features, calculatedCo2 } = props.offer;

  const location = postalCode.slice(postalCode.indexOf(" ") + 1, postalCode.lenght);

  return (
    <Link to={_id} className="group relative border border-gray-200 rounded-lg flex flex-col overflow-hidden">
      <div className="aspect-w-3 aspect-h-2 bg-gray-200 group-hover:opacity-75 sm:h-48">
        <img src={axios.defaults.baseURL + "/upload/images/" + coverImg} alt={offerName} className="w-full h-full object-center object-cover sm:w-full sm:h-full" />
      </div>
      <div className="p-4 space-y-2 flex flex-col h-full">
        <h3 className="text-sm font-medium text-gray-900">{offerName}</h3>
        <div className="flex items-center justify-between py-1">
          <div className="flex space-x-2 items-center">
            <LocationMarkerIcon className="text-gray-500 w-5 h-5" />
            <span className="text-gray-500 text-sm">{location}</span>
          </div>
          <HotelStars count={stars} />
        </div>
        <p className="text-sm text-gray-500 pb-4">{shortDescription}</p>
        <div className="flex flex-wrap items-end h-full">
          {features.map(e => (
            <FeatureBadge key={e._id}>{e.title}</FeatureBadge>
          ))}
        </div>
        <div className="pb-8" />
        <div className="flex items-center justify-center absolute bottom-0 left-0 w-full py-2 bg-green-700 text-white text-sm">
          <p>
            {parseFloat(calculatedCo2).toLocaleString("de-DE")} kg/CO<sub>2</sub> pro Übernachtung
          </p>
        </div>
      </div>
    </Link>
  );
}

export default OfferGridItem;
