import React, { useEffect, useState } from "react";
import { DottedSpinner } from "../components/Icons/CustomIcons";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

function VerifyEmail(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const [searchParams] = useSearchParams();
  const emailToken = searchParams.get("token");

  const success = (
    <div className="flex space-x-8">
      <div>
        <CheckCircleIcon className="h-10 w-10 text-green-700" />
      </div>
      <div>
        <p className="font-medium text-gray-900">Ihr Konto ist wurde erfolgreich aktiviert.</p>
        <p className="text-gray-500">Sie können das Fenster nun schließen.</p>
      </div>
    </div>
  );

  const error = (
    <div className="flex space-x-8">
      <div>
        <XCircleIcon className="h-10 w-10 text-red-500" />
      </div>
      <div>
        <p className="font-medium text-gray-900">Ihr Konto konnte nicht aktiviert werden.</p>
        <p className="text-gray-500">Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.</p>
      </div>
    </div>
  );

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.post("/verifyEmail?token=" + emailToken, { cancelToken: fetchRequest.token });
        if (response.data) {
          setMessage(success);
        } else {
          setMessage(error);
        }
        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
          setMessage(error);
          setIsLoading(false);
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {isLoading ? (
            <div className="flex items-center justify-center">
              <DottedSpinner className="h-10 w-10 text-gray-500 animate-spin" />
            </div>
          ) : (
            message
          )}
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
