import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormControl from "../../components/FormUI/FormControl";

// Store
import * as uiActions from "../../store/actions/ui";

// Components
import Page from "../../components/Page";
import { DottedSpinner } from "../../components/Icons/CustomIcons";

function DashboardOfferDetail(props) {
  const [offer, setOffer] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const { id } = useParams();

  const user = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.post("/getOfferEdit/" + id, { token: user.token }, { cancelToken: fetchRequest.token });
        setOffer(response.data);

        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
          dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  const initialValues = {
    description: offer.description,
    shortDescription: offer.shortDescription
  };

  const validationSchema = Yup.object({
    description: Yup.string(),
    shortDescription: Yup.string().max(200, "Maximal 200 Zeichen")
  });

  function onSubmit(values) {
    setIsSaving(true);
    saveChanges(values);
  }

  // function discardChanges(e) {
  //   e.preventDefault();
  // }

  async function saveChanges(values) {
    try {
      const response = await axios.post("/updateOffer/" + id, { ...values, token: user.token });
      setIsSaving(false);
      dispatch(uiActions.flashMessageDashboard("success", "Erfolgreich gespeichert"));
    } catch (e) {
      console.log("There was an problem");
      dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
    }
  }

  return (
    <Page dashboard title="Hotel">
      <div className="px-4 sm:px-6 lg:px-8">
        {isLoading ? (
          <div className="flex justify-center items-center">
            <DottedSpinner className="h-10 w-10 text-gray-500 animate-spin" />
          </div>
        ) : (
          <Formik initialValues={props.formData || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {formik => (
              <Form>
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">{offer.offerName}</h1>
                    <p className="mt-2 text-sm text-gray-700">Hier können Sie die Beschreibung Ihres Angebots anpassen</p>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex items-center">
                    {/* <button type="button" className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mr-2" onClick={discardChanges}>
                      Zurück
                    </button> */}
                    <button disabled={isSaving} type="submit" className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed">
                      Speichern {isSaving && <DottedSpinner className="w-5 text-white animate-spin ml-2" />}
                    </button>
                  </div>
                </div>
                <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg bg-white">
                  <div className="p-4">
                    <FormControl control="textarea" label="Übersichtstext" name="shortDescription" />
                  </div>
                  <div className="p-4">
                    <FormControl control="textarea" label="Beschreibung" name="description" />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </Page>
  );
}

export default DashboardOfferDetail;
