import React, { Fragment, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import axios from "axios";

// Store
import * as uiActions from "../../store/actions/ui";

// Components
import Page from "../../components/Page";
import ContentContainer from "../../components/ContentContainer";
import HotelStars from "../../components/Shop/HotelStars";
import SocialIcon from "../../components/SocialIcon";
import FeatureBadge from "../../components/Shop/FeatureBadge";
import FormControl from "../../components/FormUI/FormControl";
import { DottedSpinner } from "../../components/Icons/CustomIcons";
import LightInfoBox from "../../components/LightInfoBox";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function OfferDetail(props) {
  const [offer, setOffer] = useState({});
  const [socials, setSocials] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const user = useSelector(state => state.auth.user);
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const cartItems = useSelector(state => state.ui.cartItems);
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.get("/offers/" + id, { cancelToken: fetchRequest.token });
        setOffer(response.data);
        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
          setIsLoading(false);
          dispatch(uiActions.flashMessageShop("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  useEffect(() => {
    const socialArray = [];
    for (const social in offer.socialMedia) {
      if (Object.hasOwnProperty.call(offer.socialMedia, social)) {
        const element = offer.socialMedia[social];
        socialArray.push({
          name: social,
          link: element
        });
      }
    }
    setSocials(socialArray);
  }, [offer]);

  function getFoodTable(category) {
    const foods = offer[category];
    const tableContent = [];

    for (const key in foods) {
      if (Object.hasOwnProperty.call(foods, key)) {
        const food = foods[key];
        tableContent.push(
          <div key={food.label} className="grid grid-cols-4 gap-2 text-gray-600 text-center">
            <div className="bg-gray-200 p-1 rounded-md">{food.label}</div>
            <div className="bg-gray-100 p-1 rounded-md flex items-center justify-center">{food.regional}</div>
            <div className="bg-gray-100 p-1 rounded-md flex items-center justify-center">{food.bio}</div>
            <div className="bg-gray-100 p-1 rounded-md flex items-center justify-center">{food.fairtrade}</div>
          </div>
        );
      }
    }

    return tableContent;
  }

  const initialValues = {
    rooms: "",
    overnights: ""
  };

  const validationSchema = Yup.object({
    rooms: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").positive("Nur positive Zahlen").min(1, "Mindestens ein Zimmer").required("Pflichtfeld"),
    overnights: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").positive("Nur positive Zahlen").min(1, "Mindestens eine Übernachtung").required("Pflichtfeld")
  });

  function onSubmit(values) {
    sendData(values);
  }

  async function sendData(data) {
    setIsSubmiting(true);
    try {
      const response = await axios.post("cart/" + id, { data: data, token: user.token });
      setIsSubmiting(false);
      dispatch(uiActions.setCartItems(cartItems + 1));
      dispatch(uiActions.flashMessageShop("success", "Artikel wurde zum Einkaufswagen hinzugefügt"));
      navigate("/cart", { replace: true });
    } catch (e) {
      setIsSubmiting(false);
      dispatch(uiActions.flashMessageShop("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
      console.log("There was a problem.", e);
    }
  }

  return (
    <Page title={offer.offerName}>
      <ContentContainer>
        <div className="lg:grid lg:grid-rows-1 lg:grid-cols-7 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          {/* Product image */}
          <div className="lg:row-end-1 lg:col-span-4">
            <div className="aspect-w-4 aspect-h-3 rounded-lg bg-gray-100 overflow-hidden">{!isLoading && <img src={axios.defaults.baseURL + "/upload/images/" + offer.coverImg} alt={offer.offerName} className="object-center object-cover" />}</div>
          </div>

          {/* Product details */}
          <div className="max-w-2xl mx-auto mt-14 sm:mt-16 lg:max-w-none lg:mt-0 lg:row-end-2 lg:row-span-2 lg:col-span-3 w-full">
            <div className="flex flex-col-reverse">
              <div className="mt-4">
                <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">{offer.offerName}</h1>
              </div>
              <div>
                <h3 className="sr-only">Sterne</h3>
                <HotelStars count={offer.stars} />
              </div>
            </div>

            <div className="mt-4">
              <p>{offer.street}</p>
              <p>{offer.postalCode}</p>
            </div>

            {/* <h2 id="information-heading" className="sr-only">
              Beschreibung
            </h2>
            <p className="text-gray-500 mt-6">{offer.description}</p> */}

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount>
              {formik => (
                <Form>
                  <div className="mt-4  mb-8 grid grid-cols-4 gap-6">
                    <div className="col-span-4">
                      <div className="flex items-center justify-center w-full py-2 bg-green-700 text-white text-sm rounded-md">
                        <p>
                          {parseFloat(offer.calculatedCo2).toLocaleString("de-DE")}{" "}
                          <span className="text-xs">
                            kg/CO<sub>2</sub>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-6 bg-white p-4 rounded-md shadow">
                    {loggedIn ? (
                      <>
                        <div className="col-span-4 sm:col-span-2">
                          <FormControl control="number" label="Zimmer *" name="rooms" />
                        </div>
                        <div className="col-span-4 sm:col-span-2">
                          <FormControl control="number" label="Übernachtungen *" name="overnights" />
                        </div>
                        <div className="col-span-4 sm:col-span-4">
                          <button disabled={!formik.isValid || isSubmiting} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20 w-full">
                            {isSubmiting ? <DottedSpinner className="h-5 w-5 text-white animate-spin" /> : "In den Einkaufswagen"}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-span-4">
                          <LightInfoBox info="Bitte melden Sie sich mit Ihrem Konto an, um Ihre Emissionen zu berechen." />
                        </div>
                        <div className="col-span-4">
                          <Link to="/login" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20 w-full">
                            Anmelden
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                  <p className="text-gray-400 text-xs py-4 text-right">* Pflichtfelder</p>
                </Form>
              )}
            </Formik>
            <div className="border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Ausstattung</h3>
              <div className="mt-4 prose prose-sm text-gray-500">
                <ul role="list">{isLoading ? null : offer.features.map(e => <li key={e._id}>{e.title}</li>)}</ul>
              </div>
            </div>

            {/* <div className="border-t border-gray-200 mt-10 pt-10">
              <h3 className="text-sm font-medium text-gray-900">License</h3>
              <p className="mt-4 text-sm text-gray-500">
                {license.summary}{" "}
                <a href={license.href} className="font-medium text-indigo-600 hover:text-indigo-500">
                  Read full license
                </a>
              </p>
            </div> */}

            <div className="border-t border-gray-200 mt-10 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Hier sind wir zu finden</h3>
              <ul role="list" className="flex items-center space-x-6 mt-4">
                {socials &&
                  socials.map(social => {
                    let link = "";

                    if (social.link !== "") {
                      link = (
                        <li key={social.name}>
                          <a href={"http://" + social.link} className="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500" target="_blank">
                            <span className="sr-only">{social.name}</span>
                            <SocialIcon name={social.name} />
                          </a>
                        </li>
                      );
                    }

                    return link;
                  })}
              </ul>
            </div>
          </div>

          <div className="w-full max-w-2xl mx-auto mt-16 lg:max-w-none lg:mt-0 lg:col-span-4">
            <Tab.Group as="div">
              <div className="border-b border-gray-200">
                <Tab.List className="-mb-px flex space-x-8">
                  <Tab className={({ selected }) => classNames(selected ? "border-indigo-600 text-indigo-600" : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300", "whitespace-nowrap py-6 border-b-2 font-medium text-sm")}>Beschreibung</Tab>
                  <Tab className={({ selected }) => classNames(selected ? "border-indigo-600 text-indigo-600" : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300", "whitespace-nowrap py-6 border-b-2 font-medium text-sm")}>Lebensmittel</Tab>
                </Tab.List>
              </div>
              <Tab.Panels as={Fragment}>
                <Tab.Panel className="-mb-10">
                  <h3 className="sr-only">Beschreibung</h3>
                  <ReactMarkdown className="prose prose-sm max-w-full mt-4 mb-4" children={offer.description} remarkPlugins={[remarkGfm]} />
                </Tab.Panel>

                <Tab.Panel as="dl" className="text-sm">
                  <h3 className="sr-only">Lebensmittel</h3>

                  <div className="flex flex-col space-y-2 w-full mt-4">
                    <h2 className="bg-gray-200 text-gray-600 w-full text-center py-2 rounded-md">Nahrungsmittel</h2>
                    <div className="grid grid-cols-4 gap-2 text-gray-600 text-center text-xs">
                      <div>
                        <p className="sr-only">Name</p>
                      </div>
                      <div className="bg-gray-200 p-1 rounded-md">Regional (&lt;100 km)</div>
                      <div className="bg-gray-200 p-1 rounded-md">Bio</div>
                      <div className="bg-gray-200 p-1 rounded-md">Fairtrade</div>
                    </div>
                    {getFoodTable("foods")}
                  </div>
                  <div className="flex flex-col space-y-2 w-full mt-4">
                    <h2 className="bg-gray-200 text-gray-600 w-full text-center py-2 rounded-md">Getränke</h2>
                    <div className="grid grid-cols-4 gap-2 text-gray-600 text-center text-xs">
                      <div>
                        <p className="sr-only">Name</p>
                      </div>
                      <div className="bg-gray-200 p-1 rounded-md">Regional (&lt;100 km)</div>
                      <div className="bg-gray-200 p-1 rounded-md">Bio</div>
                      <div className="bg-gray-200 p-1 rounded-md">Fairtrade</div>
                    </div>
                    {getFoodTable("drinks")}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </ContentContainer>
    </Page>
  );
}

export default OfferDetail;
