import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// Store
import * as authActions from "./store/actions/auth";
import * as uiActions from "./store/actions/ui";

// Layouts
import Shop from "./layouts/Shop";
import Dashboard from "./layouts/Dashboard";

// Pages
import Login from "./pages/Login";
import RegisterPrivat from "./pages/RegisterPrivat";
import RegisterBusiness from "./pages/RegisterBusiness";
import VerifyEmail from "./pages/VerifyEmail";

import UploadTest from "./pages/Test/UploadTest";

// Setup API Base URL
if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://api-zenapa.vorschau.cc";
} else if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://127.0.0.1:3001";
}

function App() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const user = useSelector(state => state.auth.user);
  const messages = useSelector(state => state.ui.flashMessages);

  useEffect(() => {
    if (loggedIn) {
      localStorage.setItem("ZenapaToken", user.token);
      localStorage.setItem("ZenapaFirstName", user.firstname);
      localStorage.setItem("ZenapaLastName", user.lastname);
      localStorage.setItem("ZenapaEmail", user.email);
      localStorage.setItem("ZenapaUserRole", user.role);
      if (user.companyName) {
        localStorage.setItem("ZenapaCompanyName", user.companyName);
      }
      // localStorage.setItem("ZenapaAvatar", user.avatar);
    } else {
      localStorage.removeItem("ZenapaToken");
      localStorage.removeItem("ZenapaFirstName");
      localStorage.removeItem("ZenapaLastName");
      localStorage.removeItem("ZenapaEmail");
      localStorage.removeItem("ZenapaUserRole");
      localStorage.removeItem("ZenapaCompanyName");
      // localStorage.removeItem("ZenapaAvatar");
    }
  }, [loggedIn]);

  // Check if token has expired or not on first render
  useEffect(() => {
    if (loggedIn) {
      const fetchRequest = axios.CancelToken.source();
      let requestAborted = false;

      async function checkToken() {
        try {
          const response = await axios.post(`/checkToken`, { token: user.token }, { cancelToken: fetchRequest.token });
          if (!response.data) {
            dispatch(authActions.login(response.data));
            dispatch(uiActions.flashMessageShop("warning", "Ihre Sitzung ist abgelaufen.", "Bitte melden Sie sich erneut an."));
          }
        } catch (e) {
          if (!requestAborted) {
            console.log("There was an error");
          }
        }
      }
      checkToken();

      return () => {
        requestAborted = true;
        fetchRequest.cancel();
      };
    }
  }, []);

  return (
    <BrowserRouter>
      {/* Main Router */}
      <Suspense fallback="">
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<RegisterPrivat />} />
          <Route path="register-business" element={<RegisterBusiness />} />

          <Route path="verify-email" element={<VerifyEmail />} />

          <Route path="/*" element={<Shop />} />
          {loggedIn && user.role < 20 ? <Route path="dashboard/*" element={<Dashboard />} /> : null}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
