import { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import { XIcon } from "@heroicons/react/solid";

function Message(props) {
  const [show, setShow] = useState(true);
  const { message } = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  });

  let icon;
  let color;

  switch (message.type) {
    case "alert":
      icon = <ExclamationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />;
      color = "bg-red-500";
      break;
    case "warning":
      icon = <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />;
      color = "bg-orange-500";
      break;
    case "success":
      icon = <CheckCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />;
      color = "bg-green-700";
      break;
    case "info":
      icon = <InformationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />;
      color = "bg-indigo-500";
      break;
  }

  return (
    <Transition show={show} as={Fragment} enter="transform ease-in transition" enterFrom="" enterTo="" leave="" leaveFrom="" leaveTo="">
      <div className={"relative w-full " + color}>
        <div className="max-w-7xl mx-auto py-2 px-3 sm:px-6 lg:px-8 flex items-center justify-center">
          <div className="pr-16 sm:text-center sm:px-16 flex items-center">
            <div className="font-medium text-white inline-flex items-center text-sm">
              <div className="flex-shrink-0 mr-4">{icon}</div>
              <span className="md:hidden">{message.title}</span>
              <span className="hidden md:inline">{message.title}</span>
              <span className="block sm:ml-2 sm:inline-block font-light">{message.subline}</span>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              onClick={() => {
                setShow(false);
              }}
              type="button"
              className="flex p-1 rounded-md focus:outline-none focus:ring-2 focus:ring-white">
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
}

// Recive messgage via props
// message: {
//   title: string,
//   subline: string,
//   type: "alert" | "warning" | "success" | "info"
// }
export default function FlashMessage(props) {
  return (
    <>
      <div aria-live="assertive" className="">
        <div className="w-full flex flex-col items-center sm:items-end">
          {props.messages.map((message, index) => (
            <Message key={index} message={message} />
          ))}
        </div>
      </div>
    </>
  );
}
