import React from "react";

function Avatar({ size, username }) {
  const initials = username.split(" ");

  return (
    <span className={`inline-flex items-center justify-center h-${size} w-${size} rounded-full bg-gray-500 p-1`}>
      <span className="text-sm font-medium leading-none text-white">{initials[0][0] + "" + initials[1][0]}</span>
    </span>
  );
}

export default Avatar;
