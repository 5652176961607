import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Components
import FormControl from "../../../components/FormUI/FormControl";
import LightInfoBox from "../../../components/LightInfoBox";

// Default Values
const planeDefault = "A320-200";
const fuelDefault = "Benzin";
const fuelWasteDefault = "8";

function Step_2(props) {
  const [defaults, setDefaults] = useState({
    plane: "",
    fuel: ""
  });

  useEffect(() => {
    const planeId = props.dropdownData.planes.filter(p => p.title === planeDefault);
    const fuelId = props.dropdownData.fuels.filter(f => f.title === fuelDefault);

    setDefaults({
      plane: planeId[0]._id,
      fuel: fuelId[0]._id
    });
  }, []);

  const initialValues = {
    kfz_km: "",
    ice_time_h: "",
    ice_time_m: "",
    ic_ec_time_h: "",
    ic_ec_time_m: "",
    re_rb_time_h: "",
    re_rb_time_m: "",
    plane_time_h: "",
    plane_time_m: "",
    fuel: defaults.fuel,
    fuel_waste: fuelWasteDefault,
    plane_type: defaults.plane
  };

  const validationSchema = Yup.object({
    kfz_km: Yup.number().typeError("Keine gültige Zahl").integer("Bitte geben Sie nur ganze Zahlen ein").min(0, "Bitte geben Sie eine positive Zahl ein").max(25000, "Es dürfen maximal 25.000 km eingegeben werden"),
    ice_time_h: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(1000),
    ice_time_m: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(59),
    ic_ec_time_h: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(1000),
    ic_ec_time_m: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(59),
    re_rb_time_h: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(1000),
    re_rb_time_m: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(59),
    plane_time_h: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(1000),
    plane_time_m: Yup.number().typeError("Keine gültige Zahl").integer().min(0).max(59),
    fuel: Yup.string().required("Bitte wählen Sie die zutreffende Option aus"),
    fuel_waste: Yup.number().typeError("Keine gültige Zahl").positive("Bitte geben Sie eine positive Zahl ein").max(50, "Der Kraftstoffverbrauch darf höchstens 50 Liter betragen").required("Bitte geben Sie Ihren durchschnittlichen Kraftstoffverbrach ein"),
    plane_type: Yup.string().required("Bitte wählen Sie die zutreffende Option aus")
  });

  function onSubmit(values) {
    props.storeValues(values);
    nextHandler();
  }

  function nextHandler() {
    props.nextStepHandler();
  }

  function prevHandler(e) {
    e.preventDefault();
    props.prevStepHandler();
  }

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Anreise - Verkehrsmittel</h3>
            <p className="mt-4 text-xs text-gray-600">* Pflichtfelder</p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Formik initialValues={props.formData || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {formik => (
              <Form>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-4 gap-6">
                      <div className="col-span-4">
                        <FormControl control="number" label="Zurückgelegte (einfache) Anreisestrecke(n) bzw- Flugzeit" name="kfz_km" after="km" />
                      </div>
                      <div className="col-span-4 sm:col-span-1">
                        <FormControl control="traveltime" label="Bahn (ICE)" name="ice_time" />
                      </div>
                      <div className="col-span-4 sm:col-span-1">
                        <FormControl control="traveltime" label="Bahn (IC/EC)" name="ic_ec_time" />
                      </div>
                      <div className="col-span-4 sm:col-span-1">
                        <FormControl control="traveltime" label="Bahn (RE/RB)" name="re_rb_time" />
                      </div>
                      <div className="col-span-4 sm:col-span-1">
                        <FormControl control="traveltime" label="Flugzeug" name="plane_time" />
                      </div>
                      {/* Only if a car was used */}
                      {formik.values.kfz_km !== "" && (
                        <>
                          <div className="col-span-4 sm:col-span-2">
                            <FormControl control="select" label="Welchen Kraftstoff benötigt ihr Fahrzeug? *" name="fuel" options={props.dropdownData.fuels} />
                          </div>
                          <div className="col-span-4 sm:col-span-2">
                            <FormControl control="number" label="Wie hoch schätzen Sie den Ø-Verbrauch pro 100 km des KFZ? *" name="fuel_waste" after="l/100 km" />
                          </div>
                        </>
                      )}

                      {/* Only if a plane was used */}
                      {formik.values.plane_time_h !== "" || formik.values.plane_time_m !== "" ? (
                        <>
                          <div className="col-span-4">
                            <FormControl control="select" label="Mit welchem Flugzeugtyp sind Sie geflogen?" name="plane_type" options={props.dropdownData.planes} />
                          </div>
                          <div className="col-span-4 -mt-2 ">
                            <LightInfoBox info='Die häufigsten Fluggzeugtypen der Lufthansa sind z. B. die Airbuse A319-100, A320-200 & A321-100/200 (über 50% der Flotte). Aufgrund des erhöhten Treibstoffbedarfs in der Start- und Steigphase wird für die ersten 15 min eines Fluges der 3-fache Ø-Treibstoffbedarf angenommen. In Anlehnung an <a href="http://www.fluglaerm.de/hamburg/klima.htma" target="_blank" class="text-indigo-600">Fluglaerm.de</a>' />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex space-x-2 justify-between md:justify-end">
                    {props.stepNumber > 0 && (
                      <button onClick={prevHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Zurück
                      </button>
                    )}

                    <button disabled={!formik.isValid} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20">
                      Weiter
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Step_2;
