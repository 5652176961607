import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// Store
import * as uiActions from "../../store/actions/ui";

// Components
import Page from "../../components/Page";
import Steps from "../../components/Steps";
import StepSelector from "../../pages/Dashboard/OfferAddFormSteps/StepSelector";
import { DottedSpinner } from "../../components/Icons/CustomIcons";

function DashboardOfferDetail(props) {
  const [steps, setSteps] = useState([
    { name: "Kontaktdaten", status: "current" },
    { name: "Kontaktperson", status: "upcoming" },
    { name: "Betriebliche Informationen", status: "upcoming" },
    { name: "Ausstattung", status: "upcoming" },
    { name: "Stoff- und Energieströme - Strom", status: "upcoming" },
    { name: "Stoff- und Energieströme - Wärme", status: "upcoming" },
    { name: "Stoff- und Energieströme - Wasser", status: "upcoming" },
    { name: "Stoff- und Energieströme - Abfall", status: "upcoming" },
    { name: "Stoff- und Energieströme - Wäsche", status: "upcoming" },
    { name: "Stoff- und Energieströme - Eigenproduktion", status: "upcoming" },
    { name: "Lebensmittel - Nahrungsmittel", status: "upcoming" },
    { name: "Lebensmittel - Getränke", status: "upcoming" },
    { name: "Engagement - Klima", status: "upcoming" },
    { name: "Engagement - Bio", status: "upcoming" }
  ]);

  const [formData, setFormData] = useState([]);

  const [curStep, setCurStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownData, setDropdownData] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.get(`/offerCalculator/dropdowns`, { cancelToken: fetchRequest.token });
        setDropdownData(response.data);
        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an error");
          dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte überprüfen Sie Ihre Internetverbindung und laden Sie die Seite neu"));
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  function nextStepHandler() {
    setSteps(s => {
      const newStep = s;
      newStep[curStep].status = "complete";
      newStep[curStep + 1].status = "current";
      return newStep;
    });
    setCurStep(s => s + 1);
  }

  function prevStepHandler() {
    setSteps(s => {
      const newStep = s;
      newStep[curStep].status = "upcoming";
      newStep[curStep - 1].status = "current";
      return newStep;
    });
    setCurStep(s => s - 1);
  }

  function storeValues(values) {
    setFormData(s => {
      const newData = s;
      newData[curStep] = values;
      return newData;
    });
  }

  function calculate(values) {
    const resultData = formData;
    resultData[curStep] = values;

    let bundledData = {};

    for (let i = 0; i < formData.length; i++) {
      bundledData = { ...bundledData, ...resultData[i] };
    }

    sendData(bundledData);

    navigate("/dashboard/offers/submit-confirm", { replace: true });
  }

  async function sendData(data) {
    try {
      const formDataImage = new FormData();
      const formDataCo2File = new FormData();
      const formDataBioFile = new FormData();

      const token = user.token;

      formDataImage.append("coverImg", data.coverImg);

      formDataCo2File.append("co2CertificateFile", data.co2CertificateFile);

      formDataBioFile.append("bioCertificateFile", data.bioCertificateFile);

      const refactoredData = { ...data, coverImg: "", co2CertificateFile: "", bioCertificateFile: "" };

      const response = await axios.post("submitOffer", { offerData: refactoredData, token });

      const newOfferId = response.data;

      formDataImage.append("offerId", newOfferId);
      formDataCo2File.append("offerId", newOfferId);
      formDataBioFile.append("offerId", newOfferId);

      await axios.post("upload-image", formDataImage, {
        headers: {
          Authorization: token
        }
      });
      await axios.post("upload-co2-file", formDataCo2File, {
        headers: {
          Authorization: token
        }
      });
      await axios.post("upload-bio-file", formDataBioFile, {
        headers: {
          Authorization: token
        }
      });
    } catch (e) {
      console.log("There was a problem.", e);
    }
  }

  return (
    <Page dashboard title="Neues Angebot">
      {isLoading ? (
        <div className="flex justify-center h-screen mt-16">
          <DottedSpinner className="h-10 w-10 animate-spin text-gray-500" />
        </div>
      ) : (
        <>
          <Steps stepData={steps} />
          <StepSelector step={curStep} formData={formData} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} dropdownData={dropdownData} calculate={calculate} />
        </>
      )}
    </Page>
  );
}

export default DashboardOfferDetail;
