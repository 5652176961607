import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// Store
import * as uiActions from "../../store/actions/ui";

// Components
import Page from "../../components/Page";
import OfferFilters from "../../components/Shop/OfferFilters";
import OfferGrid from "../../components/Shop/OfferGrid";
import { DottedSpinner } from "../../components/Icons/CustomIcons";

function Offers(props) {
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.get("/offers", { cancelToken: fetchRequest.token });
        setOffers(response.data);
        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
          setIsLoading(false);
          dispatch(uiActions.flashMessageShop("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  async function updateOffers(filter) {
    try {
      const response = await axios.post("offersFiltered", { filter });
      console.log(response.data);
      setOffers(response.data);
      setIsLoading(false);
    } catch (e) {
      console.log("There was an problem");
      setIsLoading(false);
      dispatch(uiActions.flashMessageShop("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
    }
  }

  return (
    <Page title="Angebote">
      <div className="bg-white rounded-md">
        <OfferFilters updateOffers={updateOffers}>
          {isLoading ? (
            <div className="flex justify-center items-center">
              <DottedSpinner className="h-10 w-10 text-gray-500 animate-spin" />
            </div>
          ) : (
            <>{offers.length > 0 ? <OfferGrid offers={offers} /> : <div className="flex justify-center items-center text-gray-500">Keine Angebote gefunden</div>}</>
          )}
        </OfferFilters>
      </div>
    </Page>
  );
}

export default Offers;
