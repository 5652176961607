import React, { useEffect, useState } from "react";
import { XIcon, CloudIcon } from "@heroicons/react/solid";
import axios from "axios";

// Components
import { DottedSpinner } from "../../Icons/CustomIcons";

function CartItem({ item, deleteItem }) {
  const [isDeleting, setIsDeleting] = useState(false);

  function handleDelete() {
    setIsDeleting(true);
    deleteItem(item._id, item.fixedPosition);
  }

  return (
    <>
      {item.fixedPosition ? (
        <li className="shadow-sm rounded-md overflow-hidden mb-4 bg-indigo-600 relative">
          <div className="flex items-center space-x-4 text-white p-4">
            <CloudIcon className="h-10 w-10 opacity-80" />
            <div>
              Ihre Angaben zur Mobilität und Gastgewerbe ergaben <span className="font-semibold text-lg">{parseFloat(item.co2).toLocaleString("de-DE")}</span>
              <span className="text-xs ml-1">
                kg/CO<sub>2</sub>
              </span>{" "}
            </div>
            <div className="absolute top-4 right-4">
              <button type="button" onClick={handleDelete} className="-m-2 p-2 inline-flex text-white hover:opacity-100 opacity-80">
                <span className="sr-only">Entfernen</span>
                {isDeleting ? <DottedSpinner className="w-5 h-5 animate-spin" /> : <XIcon className="h-5 w-5" aria-hidden="true" />}
              </button>
            </div>
          </div>
        </li>
      ) : (
        <li className="shadow-sm rounded-md overflow-hidden mb-4">
          <div className="flex w-full bg-white p-4 relative">
            <div className="aspect-1 w-24 h-24 bg-gray-200 rounded-md overflow-hidden">
              <img src={axios.defaults.baseURL + "/upload/images/" + item.offer[0].coverImg} alt={item.offer[0].offerName} className="w-full h-full object-center object-cover sm:w-full sm:h-full" />
            </div>
            <div className="pl-4 flex flex-col justify-between">
              <div>
                <p className="text-gray-600 font-medium">{item.offer[0].offerName}</p>
                <p className="text-gray-500 text-sm">
                  {parseFloat(item.offer[0].calculatedCo2).toLocaleString("de-DE")}{" "}
                  <span className="text-xs">
                    kg/CO<sub>2</sub>
                  </span>{" "}
                </p>
              </div>
              <div className="text-gray-500 text-sm flex items-end">
                <span className="sr-only sm:not-sr-only">Berechnete Emisionen: </span>
                <div className="text-lg absolute right-14 bottom-4">
                  {parseFloat(item.co2).toLocaleString("de-DE")}{" "}
                  <span className="text-sm">
                    kg/CO<sub>2</sub>
                  </span>
                </div>
              </div>
            </div>

            <div className="absolute top-4 right-4">
              <button type="button" onClick={handleDelete} className="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500">
                <span className="sr-only">Entfernen</span>
                {isDeleting ? <DottedSpinner className="w-5 h-5 animate-spin" /> : <XIcon className="h-5 w-5" aria-hidden="true" />}
              </button>
            </div>
          </div>
        </li>
      )}
    </>
  );
}

export default CartItem;
