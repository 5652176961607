import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormControl from "../../../components/FormUI/FormControl";

// Components
import LightInfoBox from "../../../components/LightInfoBox";

function Step_4_2(props) {
  const heatingOptions = [
    { _id: "", title: "" },
    { _id: "2200", title: "Hoch" },
    { _id: "1800", title: "Mittel" },
    { _id: "1200", title: "Niedrig" }
  ];

  const initialValues = {
    heater1: {
      energySource: "",
      kwhKnown: false,
      energyConsumption: "",
      performance: "",
      constructionYear: ""
    },
    secondHeaterCheck: false,
    heater2: {
      energySource: "",
      kwhKnown: false,
      energyConsumption: "",
      performance: "",
      constructionYear: ""
    },
    weightingFirst: "",
    heatingDemand: "",
    localHeatEnergySource: "",
    greenEnergy: false
  };

  const validationSchema = Yup.object({
    heater1: Yup.object({
      energySource: Yup.string().required("Pflichtfeld"),
      kwhKnown: Yup.boolean(),
      energyConsumption: Yup.number()
        .typeError("Keine gültige Zahl")
        .min(0, "Nur positive Werte")
        .when("kwhKnown", {
          is: true,
          then: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte").required("Pflichtfeld")
        }),
      performance: Yup.number()
        .typeError("Keine gültige Zahl")
        .min(0, "Nur positive Werte")
        .when("kwhKnown", {
          is: false,
          then: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte").required("Pflichtfeld")
        }),
      constructionYear: Yup.string().when("kwhKnown", {
        is: false,
        then: Yup.string().required("Pflichtfeld")
      })
    }),
    secondHeaterCheck: Yup.boolean(),
    heater2: Yup.object({
      energySource: Yup.string().when("secondHeaterCheck", {
        is: true,
        then: Yup.string().required("Pflichtfeld")
      }),
      kwhKnown: Yup.boolean(),
      energyConsumption: Yup.number()
        .typeError("Keine gültige Zahl")
        .min(0, "Nur positive Werte")
        .when(["kwhKnown", "secondHeaterCheck"], {
          is: (kwhKnown, secondHeaterCheck) => {
            return kwhKnown && secondHeaterCheck;
          },
          then: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte").required("Pflichtfeld")
        }),
      performance: Yup.number()
        .typeError("Keine gültige Zahl")
        .min(0, "Nur positive Werte")
        .when(["kwhKnown", "secondHeaterCheck"], {
          is: (kwhKnown, secondHeaterCheck) => {
            return !kwhKnown && secondHeaterCheck;
          },
          then: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte").required("Pflichtfeld")
        }),
      constructionYear: Yup.string().when(["kwhKnown", "secondHeaterCheck"], {
        is: (kwhKnown, secondHeaterCheck) => {
          return !kwhKnown && secondHeaterCheck;
        },
        then: Yup.string().required("Pflichtfeld")
      })
    }),
    weightingFirst: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte").max(100, "Maximal 100"),
    heatingDemand: Yup.string().required("Pflichtfeld"),
    localHeatEnergySource: Yup.string(),
    greenEnergy: Yup.boolean()
  });

  function onSubmit(values) {
    const refactorData = { ...values };

    refactorData.heater1.energyConsumption = refactorData.heater1.energyConsumption === "" ? 0 : refactorData.heater1.energyConsumption;
    refactorData.heater1.performance = refactorData.heater1.performance === "" ? 0 : refactorData.heater1.performance;

    refactorData.heater2.energyConsumption = refactorData.heater2.energyConsumption === "" ? 0 : refactorData.heater2.energyConsumption;
    refactorData.heater2.performance = refactorData.heater2.performance === "" ? 0 : refactorData.heater2.performance;

    refactorData.weightingFirst = refactorData.weightingFirst === "" ? 0 : refactorData.weightingFirst;

    props.storeValues(refactorData);
    nextHandler();
  }

  function nextHandler() {
    props.nextStepHandler();
  }

  function prevHandler(e) {
    e.preventDefault();
    props.prevStepHandler();
  }

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Stoff- und Energieströme</h3>
            <p className="mt-4 text-md text-gray-600">Wärme</p>
            <p className="mt-4 text-xs text-gray-600">* Pflichtfelder</p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Formik initialValues={props.formData || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {formik => (
              <Form>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-4 gap-6">
                      <div className="col-span-4">
                        <p className="mb-1 block text-md font-semibold text-gray-700">Erste Energiequelle</p>
                      </div>
                      <div className="col-span-4">
                        <FormControl control="select" label="Energieträger *" name="heater1.energySource" options={[{ _id: null, title: "" }, ...props.dropdownData.energySources]} />
                      </div>
                      <div className="col-span-4 flex items-center">
                        <FormControl control="checkbox" label="Energieverbrauch (in kWh) bekannt?" name="heater1.kwhKnown" simple />
                      </div>
                      {formik.values.heater1.kwhKnown ? (
                        <>
                          <div className="col-span-4 sm:col-span-2">
                            <FormControl control="number" label="Jährlicher Energieverbrauch *" name="heater1.energyConsumption" after="kWh/Jahr" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-span-4 sm:col-span-2">
                            <FormControl control="number" label="Leistung *" name="heater1.performance" after="kW" />
                          </div>
                          <div className="col-span-4 sm:col-span-2">
                            <FormControl control="select" label="Baujahr *" name="heater1.constructionYear" options={[{ _id: null, title: "" }, ...props.dropdownData.heaterConstructionYear]} />
                          </div>
                        </>
                      )}

                      <div className="col-span-4" aria-hidden="true">
                        <div className="py-5">
                          <div className="border-t border-gray-200" />
                        </div>
                      </div>

                      <div className="col-span-4">
                        <FormControl control="checkbox" label="Wird eine zweite Energiequelle verwendet?" name="secondHeaterCheck" simple />
                      </div>

                      <div className="col-span-4" aria-hidden="true">
                        <div className="py-5">
                          <div className="border-t border-gray-200" />
                        </div>
                      </div>

                      {formik.values.secondHeaterCheck && (
                        <>
                          <div className="col-span-4">
                            <p className="mb-1 block text-md font-semibold text-gray-700">Zweite Energiequelle</p>
                          </div>
                          <div className="col-span-4">
                            <FormControl control="select" label="Energieträger *" name="heater2.energySource" options={[{ _id: null, title: "" }, ...props.dropdownData.energySources]} />
                          </div>
                          <div className="col-span-4">
                            <FormControl control="checkbox" label="Energieverbrauch (in kWh) bekannt?" name="heater2.kwhKnown" simple />
                          </div>
                          {formik.values.heater2.kwhKnown ? (
                            <>
                              <div className="col-span-4 sm:col-span-2">
                                <FormControl control="number" label="Jährlicher Energieverbrauch *" name="heater2.energyConsumption" after="kWh/Jahr" />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-span-4 sm:col-span-2">
                                <FormControl control="number" label="Leistung *" name="heater2.performance" after="kW" />
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <FormControl control="select" label="Baujahr *" name="heater2.constructionYear" options={[{ _id: null, title: "" }, ...props.dropdownData.heaterConstructionYear]} />
                              </div>
                            </>
                          )}
                          <div className="col-span-4" aria-hidden="true">
                            <div className="py-5">
                              <div className="border-t border-gray-200" />
                            </div>
                          </div>
                          <div className="col-span-4 mt-8">
                            <FormControl control="input" label="Geschätzte Gewichtung (Prozentsatz des ersten Energieträgers)" name="weightingFirst" after="%" />
                          </div>
                          <div className="col-span-4 -mt-2">
                            <LightInfoBox info="Wie viel Prozent des Heizenergieverbrauchs fällt auf den <strong>ersten Energieträger</strong>? Wenn es sich nicht um ein Hybrid-System handelt, bitte 100% eintragen." />
                          </div>
                        </>
                      )}

                      <div className="col-span-4">
                        <FormControl control="select" label="Heizbedraf *" name="heatingDemand" options={heatingOptions} />
                      </div>
                      <div className="col-span-4 -mt-2">
                        <LightInfoBox
                          info="<p>Wie hoch ist die durchschnittliche Heizdauer?</p>
                                <p>Hoch = ganztags, 7 Tage/Woche</p> 
                                <p>Mittel = halbtags, 7 Tage/Woche</p>
                                <p>Niedrig = halbtags, <7 Tage/Woche</p>"
                        />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="select" label="Energieträger Nahwärme" name="localHeatEnergySource" options={[{ _id: null, title: "" }, ...props.dropdownData.optimizedEnergySourcesClean]} />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="checkbox" label="100% - Ökostromtarif?" name="greenEnergy" simple />
                      </div>
                      <div className="col-span-4 -mt-2">
                        <LightInfoBox info="Beziehen Sie Ihren Strom zum Betrieb der Heizung aus einem 100%-Ökostromtarif" />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex space-x-2 justify-between md:justify-end">
                    {props.stepNumber > 0 && (
                      <button onClick={prevHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Zurück
                      </button>
                    )}

                    <button disabled={!formik.isValid} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20">
                      Weiter
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Step_4_2;
