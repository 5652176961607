import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { DottedSpinner } from "../Icons/CustomIcons";

function DashboardRegistryItem(props) {
  const { _id, companyName, firstname, lastname, email, phone, confirmed } = props.registry;
  const { confirmFn, declineFn } = props;

  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [isLoadingDecline, setIsLoadingDecline] = useState(false);

  useEffect(() => {
    if (isLoadingConfirm || isLoadingDecline) {
      setIsLoadingConfirm(false);
      setIsLoadingDecline(false);
    }
  }, [confirmed]);

  function confirm() {
    setIsLoadingConfirm(true);
    confirmFn(_id, companyName);
  }

  function decline() {
    setIsLoadingDecline(true);
    declineFn(_id, companyName);
  }

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{companyName}</td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {firstname} {lastname}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <p>{email}</p>
        <p>{phone}</p>
      </td>
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex flex-col space-y-2 sm:space-x-2 sm:space-y-0 sm:flex-row justify-end">
        <button disabled={isLoadingConfirm || isLoadingConfirm} onClick={confirm} type="button" className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 disabled:opacity-50 disabled:cursor-not-allowed">
          {isLoadingConfirm ? <DottedSpinner className="h-5 w-5 sm:mr-2 animate-spin" /> : <CheckIcon className="h-5 w-5 sm:mr-2" />}
          <span className="hidden sm:block">
            <span className="sr-only">{companyName}, </span>Zulassen
          </span>
        </button>
        <button disabled={isLoadingConfirm || isLoadingConfirm} onClick={decline} type="button" className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed">
          {isLoadingDecline ? <DottedSpinner className="h-5 w-5 sm:mr-2 animate-spin" /> : <XIcon className="h-5 w-5 sm:mr-2" />}
          <span className="hidden sm:block">
            <span className="sr-only">{companyName}, </span>Ablehnen
          </span>
        </button>
      </td>
    </tr>
  );
}

export default DashboardRegistryItem;
