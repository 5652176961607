import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
// import FormControl from "../../../components/FormUI/FormControl";
import RangeSelector from "../../../components/FormUI/RangeSelector";

function Step_5_1(props) {
  const initialValues = {
    foods: {
      meat: {
        label: "Fleisch und Wurstwaren",
        regional: 0,
        bio: 0,
        fairtrade: 0
      },
      fish: {
        label: "Fisch",
        regional: 0,
        bio: 0,
        fairtrade: 0
      },
      vegetables: {
        label: "Gemüse",
        regional: 0,
        bio: 0,
        fairtrade: 0
      },
      potatoes: {
        label: "Kartoffeln",
        regional: 0,
        bio: 0,
        fairtrade: 0
      },
      noodles: {
        label: "Nudeln",
        regional: 0,
        bio: 0,
        fairtrade: 0
      },
      rice: {
        label: "Reis",
        regional: 0,
        bio: 0,
        fairtrade: 0
      },
      fruits: {
        label: "Obst",
        regional: 0,
        bio: 0,
        fairtrade: 0
      },
      bread: {
        label: "Backwaren",
        regional: 0,
        bio: 0,
        fairtrade: 0
      },
      milk: {
        label: "Molkereiprodukte",
        regional: 0,
        bio: 0,
        fairtrade: 0
      },
      eggs: {
        label: "Eier",
        regional: 0,
        bio: 0,
        fairtrade: 0
      }
    }
  };

  const validationSchema = Yup.object({});

  function onSubmit(values) {
    props.storeValues(values);
    nextHandler();
  }

  function nextHandler() {
    props.nextStepHandler();
  }

  function prevHandler(e) {
    e.preventDefault();
    props.prevStepHandler();
  }

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Lebensmittel - Nahrungsmittel</h3>
            <p className="mt-1 text-sm text-gray-600">Bewegen Sie die Maus über das Nahrungsmittel, um die Steuerelemente anzuzeigen.</p>
            <p className="mt-1 text-sm text-gray-600">Als regional zählen Nahrungsmittel mit Transportwegen unter 100 km.</p>
            {/* <p className="mt-4 text-xs text-gray-600">* Pflichtfelder</p> */}
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Formik initialValues={props.formData || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {formik => (
              <Form>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="sm:grid grid-cols-4 gap-6 hidden">
                      <div className="bg-gray-100 h-full rounded-md w-full flex items-center p-2">
                        <p className="text-center sm:text-left block text-sm font-semibold sm:font-medium text-gray-700 w-full">Nahrungsmittel</p>
                      </div>
                      <div className="bg-gray-100 h-full rounded-md w-full flex items-center p-2">
                        <p className="text-center sm:text-left block text-sm font-semibold sm:font-medium text-gray-700 w-full">Regional</p>
                      </div>
                      <div className="bg-gray-100 h-full rounded-md w-full flex items-center p-2">
                        <p className="text-center sm:text-left block text-sm font-semibold sm:font-medium text-gray-700 w-full">Bio</p>
                      </div>
                      <div className="bg-gray-100 h-full rounded-md w-full flex items-center p-2">
                        <p className="text-center sm:text-left block text-sm font-semibold sm:font-medium text-gray-700 w-full">Fairtrade</p>
                      </div>
                    </div>
                    {Object.keys(initialValues.foods).map(name => (
                      <RangeSelector key={name} name={`foods.${name}`} label={formik.values.foods[name].label} />
                    ))}
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex space-x-2 justify-between md:justify-end">
                    {props.stepNumber > 0 && (
                      <button onClick={prevHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Zurück
                      </button>
                    )}

                    <button disabled={!formik.isValid} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20">
                      Weiter
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Step_5_1;
