import React, { useEffect } from "react";
import FormControl from "./FormControl";

function RangeSelector(props) {
  const options = [
    { id: "1", label: "Regional", name: `${props.name}.regional` },
    { id: "2", label: "Bio", name: `${props.name}.bio` },
    { id: "3", label: "Fairtrade", name: `${props.name}.fairtrade` }
  ];

  return (
    <div className="grid grid-cols-4 gap-6 group">
      <div className="col-span-4 sm:col-span-1 flex items-center pt-1 ">
        <div className="bg-gray-100 h-full rounded-md w-full flex items-center p-2">
          <p className="text-center sm:text-left block text-sm font-semibold sm:font-medium text-gray-700 w-full">{props.label}</p>
        </div>
      </div>
      {options.map(option => (
        <div key={option.id} className="col-span-4 sm:col-span-1">
          <p className="sm:hidden text-center block text-sm font-medium text-gray-700">{option.label}</p>
          <FormControl control="input" name={option.name} disabled readOnly after="%" />
          <FormControl control="range" name={option.name} min="0" max="100" step="5" className="w-full mt-2 mb-2 invisible group-hover:visible" />
        </div>
      ))}
    </div>
  );
}

export default RangeSelector;
