import React from "react";
import { Link } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/solid";
import { offerStatusTypes } from "../../utils";

// Components
import Badge from "../Badge";

function DashboardOffersItem(props) {
  const { _id, offerName, calculatedCo2, status } = props.offer;
  let statusBadge = null;

  switch (status) {
    case offerStatusTypes.ACCEPTED:
      statusBadge = <Badge type="success" text="Online" />;
      break;
    case offerStatusTypes.PENDING:
      statusBadge = <Badge type="warning" text="In Bearbeitung" />;
      break;
    case offerStatusTypes.REJECTED:
      statusBadge = <Badge type="danger" text="Abgelehnt" />;
      break;

    default:
      break;
  }

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{offerName}</td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {status === offerStatusTypes.ACCEPTED ? (
          <>
            {calculatedCo2.replace(".", ",")}{" "}
            <span className="text-xs">
              kg/CO<sub>2</sub>
            </span>
          </>
        ) : (
          <>-</>
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{statusBadge}</td>
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <Link to={_id} className="text-indigo-600 hover:text-indigo-900 hidden sm:block">
          <span className="sr-only">{offerName}, </span>Bearbeiten
        </Link>
        <Link to={_id} className="sm:hidden inline-flex items-center p-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-transparent  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span className="sr-only">{offerName}, bearbeiten </span>
          <PencilIcon className="h-5 w-5 text-indigo-600" />
        </Link>
      </td>
    </tr>
  );
}

export default DashboardOffersItem;
