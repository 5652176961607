import React, { useEffect } from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

// Components
import Page from "../components/Page";

function InfoScreen({ pageTitle, infoText, link }) {
  return (
    <Page dashboard title={pageTitle}>
      <div className="max-w-3xl mx-auto">
        <div className="bg-white p-4 shadow rounded-md flex space-x-4 text-gray-500 w-full">
          <div>
            <InformationCircleIcon className="h-6 w-6 text-gray-400" />
          </div>
          <div>
            <p>{infoText}</p>
          </div>
        </div>
        {link && (
          <div className="flex justify-center items-center p-4">
            <Link to={link.to} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed">
              {link.text}
            </Link>
          </div>
        )}
      </div>
    </Page>
  );
}

export default InfoScreen;
