import React from "react";

function Badge({ type, text }) {
  let badge;

  switch (type) {
    case "danger":
      badge = <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">{text}</span>;
      break;
    case "warning":
      badge = <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">{text}</span>;
      break;
    case "success":
      badge = <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">{text}</span>;
      break;
    case "info":
      badge = <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">{text}</span>;
      break;
    case "neutral":
      badge = <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">{text}</span>;
      break;

    default:
      break;
  }

  return badge;
}

export default Badge;
