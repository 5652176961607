import React, { useEffect, useState } from "react";
import axios from "axios";
import { CursorClickIcon, CloudIcon, CalculatorIcon, HomeIcon, UsersIcon, BriefcaseIcon, StatusOnlineIcon } from "@heroicons/react/outline";
import { useSelector, useDispatch } from "react-redux";

// Store
import * as authActions from "../../store/actions/auth";
import * as uiActions from "../../store/actions/ui";

// Components
import Page from "../../components/Page";
import Stats from "../../components/Dashboard/Stats";
import { DottedSpinner } from "../../components/Icons/CustomIcons";

function OverviewCompany(props) {
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const offerCount = await axios.post("/getOfferCountById", { token: user.token }, { cancelToken: fetchRequest.token });
        const calculationCount = await axios.post("/getCalculationCountById", { token: user.token }, { cancelToken: fetchRequest.token });
        const totalCo2Count = await axios.post("/getCo2TotalById", { token: user.token }, { cancelToken: fetchRequest.token });
        // Durchgeführte Berechnungen
        // Gesamte CO2 Emissionen
        // const offerCount = 2;
        // const calculationCount = 12;
        // const totalCo2Count = "10.512";
        setStats([
          { id: 1, name: "Angebote Online", stat: offerCount.data, icon: StatusOnlineIcon },
          { id: 2, name: "Berechnungen", stat: calculationCount.data, icon: CalculatorIcon },
          {
            id: 3,
            name: "Gesamter Ausgleich",
            stat: parseFloat(totalCo2Count.data).toLocaleString("de-DE"),
            icon: CloudIcon,
            unit: props => (
              <span className="text-sm">
                {" "}
                kg/CO<sub>2</sub>
              </span>
            )
          }
          // { id: 2, name: "Angebote Online", stat: offerCount, icon: CloudIcon }
          // {
          //   id: 3,
          //   name: "Gesamter Ausgleich",
          //   stat: "4.523",
          //   icon: CloudIcon,
          //   unit: props => (
          //     <span className="text-sm">
          //       {" "}
          //       kg/CO<sub>2</sub>
          //     </span>
          //   )
          // }
        ]);

        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
          setIsLoading(false);
          dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  return (
    <Page dashboard title="Übersicht">
      {isLoading ? (
        <div className="flex items-center justify-center p-8">
          <DottedSpinner className="w-10 h-10 text-gray-500 animate-spin" />
        </div>
      ) : (
        <Stats statData={stats} />
      )}
    </Page>
  );
}

export default OverviewCompany;
