import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { DottedSpinner } from "../components/Icons/CustomIcons";

// Store
import * as authActions from "../store/actions/auth";
import * as uiActions from "../store/actions/ui";

// Components
import FormControl from "../components/FormUI/FormControl";

function RegisterPrivat(props) {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: ""
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required("Pflichtfeld"),
    lastname: Yup.string().required("Pflichtfeld"),
    email: Yup.string().email("Keine gültige Email").required("Pflichtfeld"),
    password: Yup.string().min(8, "Mindestens 8 Zeichen").required("Pflichtfeld"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwörter stimmen nicht überein")
      .required("Pflichtfeld")
  });

  function onSubmit(values) {
    setIsLoading(true);
    register(values);
  }

  async function register(values) {
    try {
      const response = await axios.post(`/registerUser`, values);
      dispatch(uiActions.flashMessageShop("success", "Registrierung erfolgreich", "Sie erhalten in kürze eine Email zur Aktivierung Ihres Kontos"));
    } catch (e) {
      console.log("There was an error");
      dispatch(uiActions.flashMessageShop("alert", "Registrierung fehlgeschlagen"));
    }

    navigate("/", { replace: true });
  }

  function handleCheckbox(e) {
    setAcceptTerms(e.target.checked);
  }

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen">
      <div className="sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount>
            {formik => (
              <Form>
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <FormControl control="input" label="Vorname *" name="firstname" />
                  </div>
                  <div>
                    <FormControl control="input" label="Nachname *" name="lastname" />
                  </div>
                  <div className="col-span-2">
                    <FormControl control="email" label="Email *" name="email" />
                  </div>
                  <div className="col-span-2">
                    <FormControl control="password" label="Passwort *" name="password" />
                  </div>
                  <div className="col-span-2">
                    <FormControl control="password" label="Passwort wiederholen *" name="confirmPassword" />
                  </div>
                </div>

                <div className="flex items-center justify-between my-8">
                  <div className="flex items-center">
                    <input id="privacy" name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" checked={acceptTerms} onChange={handleCheckbox} />
                    <label htmlFor="privacy" className="ml-2 block text-sm text-gray-500">
                      Hiermit bestätige ich, dass ich die{" "}
                      <Link to="/datenschutzerklaerung" className="text-indigo-600">
                        Datenschutzerklärung
                      </Link>{" "}
                      zur Kenntnis genommen habe, sowie dass ich mit der Verarbeitung meiner angegebenen Daten einverstanden bin. *
                    </label>
                  </div>
                </div>

                <div>
                  <button disabled={!acceptTerms || !formik.isValid || isLoading} type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-25">
                    {isLoading ? <DottedSpinner className="w-5 h-5 text-white animate-spin" /> : "Konto erstellen"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="flex items-center justify-center mt-8">
          <div className="flex items-center">
            <p htmlFor="privacy" className="block text-xs text-gray-500">
              Sie möchten Ihr Unternehmen registrieren?{" "}
              <Link to="/register-business" className="text-indigo-600 text-xs">
                Hier geht es zur Registriegung
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPrivat;
