import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CalculatorIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// Store
import * as uiActions from "../../store/actions/ui";

// Components
import Page from "../../components/Page";
import CartItem from ".././../components/Shop/Cart/CartItem";
import LightInfoBox from "../../components/LightInfoBox";
import { DottedSpinner } from "../../components/Icons/CustomIcons";

function Cart(props) {
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [hasFixedPosition, setHasFixedPosition] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.auth.user);
  const cartItemsCount = useSelector(state => state.ui.cartItems);

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.post("/cart", { token: user.token }, { cancelToken: fetchRequest.token });
        setCartItems(response.data);

        const fixedPositions = response.data.filter(e => e.fixedPosition === true);

        setHasFixedPosition(fixedPositions.length > 0);

        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
          setIsLoading(false);
          dispatch(uiActions.flashMessageShop("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  useEffect(() => {
    let totalCo2 = 0;
    if (cartItems.length > 0) {
      cartItems.forEach(item => {
        totalCo2 += parseFloat(item.co2);
      });
      setCartTotal(totalCo2);
    } else {
      setCartTotal(totalCo2);
    }
  }, [cartItems]);

  async function deleteCartItem(id, isFixedPosition) {
    try {
      const response = await axios.delete("cart/" + id, { data: { token: user.token } });
      setCartItems(e => e.filter(e => e._id !== id));
      if (isFixedPosition) {
        setHasFixedPosition(false);
      } else {
        dispatch(uiActions.setCartItems(cartItemsCount - 1));
      }
      dispatch(uiActions.flashMessageShop("success", "Artikel wurde aus dem Einkaufswagen entfernt"));
    } catch (e) {
      dispatch(uiActions.flashMessageShop("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
      console.log("There was a problem.", e);
    }
  }

  let itemList = null;

  if (isLoading) {
    itemList = (
      <li>
        <div className="flex items-center justify-center">
          <DottedSpinner className="h-10 w-10 text-gray-400 animate-spin" />
        </div>
      </li>
    );
  } else {
    itemList = (
      <>
        {!hasFixedPosition && (
          <li className="shadow-sm rounded-md overflow-hidden mb-4 bg-indigo-600 px-4">
            <div className="flex items-center space-x-4 text-white justify-between">
              <CalculatorIcon className="h-20 w-20 opacity-80 " />
              <div className="text-sm sm:text-base w-full">Weitere Emissionen der Reise berechnen</div>
              <div className="sm:w-auto sm:h-auto w-full h-full flex justify-end items-center">
                <Link className="bg-white opacity-80 hover:opacity-100 py-2 px-4 rounded-md text-sm text-indigo-600 sm:w-40 text-center" to="/calculator">
                  Zum Rechner
                </Link>
              </div>
            </div>
          </li>
        )}
        {cartItems.map(item => (
          <CartItem key={item._id} item={item} deleteItem={deleteCartItem} />
        ))}
      </>
    );
  }

  async function handleOrder(e) {
    e.preventDefault();
    try {
      const response = await axios.patch("cart/order", { token: user.token });
      dispatch(uiActions.setCartItems(0));
      dispatch(uiActions.flashMessageShop("success", "Emissionen wurden ausgelichen"));
      window.open("https://www.moorfutures.de");
      navigate("/", { replace: true });
    } catch (e) {
      dispatch(uiActions.flashMessageShop("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
      console.log("There was a problem.", e);
    }
  }

  return (
    <Page title="Einkaufswagen">
      <div>
        <div className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <form className="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
            <section aria-labelledby="cart-heading" className="lg:col-span-7">
              <h2 id="cart-heading" className="sr-only">
                Emissionen im Einkaufwagen
              </h2>

              <ul role="list">{itemList}</ul>
            </section>

            {/* Order summary */}
            <section aria-labelledby="summary-heading" className="mt-16 bg-gray-50 rounded-md px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5 shadow-sm">
              <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                Zusammenfassung
              </h2>

              <dl className="mt-6 space-y-4">
                <div className="flex items-center justify-between">
                  <dt className="text-sm text-gray-600">Gesamt Emissionen</dt>
                  <dd className="text-sm font-medium text-gray-900 flex space-x-2 items-center">
                    {isLoading ? <DottedSpinner className="h-4 w-4 text-gray-400 animate-spin" /> : cartTotal.toLocaleString("de-DE")}
                    <span className="text-xs ml-2">
                      kg/CO<sub>2</sub>
                    </span>
                  </dd>
                </div>
                <LightInfoBox info="<strong>Bitte notieren Sie sich Ihr Ergebnis.</strong> Durch betätigen der Schaltfläche werden Sie zur Webseite eines Ausgleichspartners weitergeleitet. Dort können Sie Ihre hier berechneten Emissionen ausgleichen." />
              </dl>

              <div className="mt-6">
                <button onClick={handleOrder} disabled={cartItems.length === 0 || !hasFixedPosition || isOrdering} type="submit" className="w-full bg-green-700 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-green-600 disabled:opacity-50 disabled:cursor-not-allowed flex justify-center items-center relative">
                  {isOrdering && <DottedSpinner className="h-6 w-6 text-white animate-spin absolute" />}
                  <span className={isOrdering ? "invisible" : ""}>Jetzt ausgleichen</span>
                </button>
              </div>
            </section>
          </form>
        </div>
      </div>
    </Page>
  );
}

export default Cart;
