import React from "react";

// Steps
import STEP_0 from "./Step_0";
import STEP_1 from "./Step_1";
import STEP_2 from "./Step_2";
import STEP_3 from "./Step_3";
import STEP_4_1 from "./Step_4_1";
import STEP_4_2 from "./Step_4_2";
import STEP_4_3 from "./Step_4_3";
import STEP_4_4 from "./Step_4_4";
import STEP_4_5 from "./Step_4_5";
import STEP_4_6 from "./Step_4_6";
import STEP_5_1 from "./Step_5_1";
import STEP_5_2 from "./Step_5_2";
import STEP_6_1 from "./Step_6_1";
import STEP_6_2 from "./Step_6_2";

function StepSelector({ step, storeValues, nextStepHandler, prevStepHandler, formData, dropdownData, calculate }) {
  switch (step) {
    case 0:
      return <STEP_0 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} dropdownData={dropdownData} />;
    case 1:
      return <STEP_1 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} />;
    case 2:
      return <STEP_2 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} />;
    case 3:
      return <STEP_3 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} dropdownData={dropdownData} />;
    case 4:
      return <STEP_4_1 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} />;
    case 5:
      return <STEP_4_2 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} dropdownData={dropdownData} />;
    case 6:
      return <STEP_4_3 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} />;
    case 7:
      return <STEP_4_4 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} />;
    case 8:
      return <STEP_4_5 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} />;
    case 9:
      return <STEP_4_6 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} />;
    case 10:
      return <STEP_5_1 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} />;
    case 11:
      return <STEP_5_2 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} />;
    case 12:
      return <STEP_6_1 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} />;
    case 13:
      return <STEP_6_2 stepNumber={step} nextStepHandler={nextStepHandler} prevStepHandler={prevStepHandler} storeValues={storeValues} formData={formData[step]} calculate={calculate} />;

    default:
      return <h1>Step not found</h1>;
  }
}

export default StepSelector;
