import { Fragment, useState, useEffect } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon, FilterIcon, MinusSmIcon, PlusSmIcon, ViewGridIcon } from "@heroicons/react/solid";
import { Formik, Form } from "formik";
import FormControl from "../../components/FormUI/FormControl";
import axios from "axios";
import { useDispatch } from "react-redux";

// Store
import * as uiActions from "../../store/actions/ui";

import { DottedSpinner } from "../../components/Icons/CustomIcons";

const sortOptions = [
  { name: "Alphabetisch (A-Z)", href: "#", current: true },
  { name: "Alphabetisch (Z-A)", href: "#", current: false },
  { name: "Sterne (meiste zuerst)", href: "#", current: false },
  { name: "Sterne (wenigste zuerst)", href: "#", current: false }
];

const Star = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" {...props}>
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function OfferFilters(props) {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterData, setFilterData] = useState(null);
  const [filterForm, setFilterForm] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.get(`/offerFilters`, { cancelToken: fetchRequest.token });
        setFilterData(response.data);
        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an error");
          dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte überprüfen Sie Ihre Internetverbindung und laden Sie die Seite erneut"));
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  useEffect(() => {
    if (filterData) {
      setFilterForm([
        {
          id: "categories",
          name: "Kategorie",
          options: filterData.offerCategories
        },
        {
          id: "stars",
          name: "Sterne",
          options: [
            { _id: "1", title: <Star className="w-5 h-5 text-yellow-300" /> },
            {
              _id: "2",
              title: (
                <div className="flex">
                  <Star className="w-5 h-5 text-yellow-300" />
                  <Star className="w-5 h-5 text-yellow-300" />
                </div>
              )
            },
            {
              _id: "3",
              title: (
                <div className="flex">
                  <Star className="w-5 h-5 text-yellow-300" />
                  <Star className="w-5 h-5 text-yellow-300" />
                  <Star className="w-5 h-5 text-yellow-300" />
                </div>
              )
            },
            {
              _id: "4",
              title: (
                <div className="flex">
                  <Star className="w-5 h-5 text-yellow-300" />
                  <Star className="w-5 h-5 text-yellow-300" />
                  <Star className="w-5 h-5 text-yellow-300" />
                  <Star className="w-5 h-5 text-yellow-300" />
                </div>
              )
            },
            {
              _id: "5",
              title: (
                <div className="flex">
                  <Star className="w-5 h-5 text-yellow-300" />
                  <Star className="w-5 h-5 text-yellow-300" />
                  <Star className="w-5 h-5 text-yellow-300" />
                  <Star className="w-5 h-5 text-yellow-300" />
                  <Star className="w-5 h-5 text-yellow-300" />
                </div>
              )
            }
          ]
        },
        {
          id: "features",
          name: "Ausstattung",
          options: filterData.offerFeatures
        }
      ]);
    }
  }, [filterData]);

  const initialValues = {
    categories: [],
    stars: [],
    features: [],
    sortOption: ""
  };

  function onSubmit(values) {
    props.updateOffers(values);
  }

  return (
    <div>
      {/* Mobile filter dialog */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setMobileFiltersOpen}>
          <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="translate-x-full">
            <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 flex items-center justify-between">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button type="button" className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400" onClick={() => setMobileFiltersOpen(false)}>
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {isLoading ? (
                <div className="flex justify-center items-center">
                  <DottedSpinner className="h-10 w-10 text-gray-400 animate-spin" />
                </div>
              ) : (
                <Formik initialValues={props.formData || initialValues} onSubmit={onSubmit} enableReinitialize>
                  {formik => (
                    <Form className="mt-4 border-t border-gray-200">
                      {filterForm.map(section => (
                        <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
                          {({ open }) => (
                            <>
                              <h3 className="-mx-2 -my-3 flow-root">
                                <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500">
                                  <span className="font-medium text-gray-900">{section.name}</span>
                                  <span className="ml-6 flex items-center">{open ? <MinusSmIcon className="h-5 w-5" aria-hidden="true" /> : <PlusSmIcon className="h-5 w-5" aria-hidden="true" />}</span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel className="pt-6">
                                <div className="space-y-6">
                                  <FormControl control="checkbox" label="" name={section.id} options={section.options} submitForm={formik.submitForm} listed />
                                  {/* {section.options.map(option => (
                  <div key={option.value} className="flex items-center">
                    <input id={option._id} name={`${section._id}[]`} defaultValue={option.value} type="checkbox" defaultChecked={option.checked} className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" />
                    <label htmlFor={`filter-mobile-${section.id}-${optionIdx}`} className="ml-3 min-w-0 flex-1 text-gray-500">
                      {option.label}
                    </label>
                  </div>
                ))} */}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative z-10 flex items-baseline justify-between mt-20 pt-6 pb-6 border-b border-gray-200">
          <h1 className="text-4xl font-thin tracking-tight text-gray-900">Angebote</h1>

          <div className="flex items-center">
            <Menu as="div" className="relative inline-block text-left">
              {/* <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sortieren
                  <ChevronDownIcon className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                </Menu.Button>
              </div> */}

              <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {/* {sortOptions.map(option => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <Link to={option.href} className={classNames(option.current ? "font-medium text-gray-900" : "text-gray-500", active ? "bg-gray-100" : "", "block px-4 py-2 text-sm")}>
                            {option.name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))} */}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <button type="button" className="p-2 -m-2 ml-4 sm:ml-6 text-gray-400 hover:text-gray-500 lg:hidden" onClick={() => setMobileFiltersOpen(true)}>
              <span className="sr-only">Filters</span>
              <FilterIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        <section aria-labelledby="products-heading" className="pt-6 pb-10 mb-16">
          <h2 id="products-heading" className="sr-only">
            Products
          </h2>

          <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
            {isLoading ? (
              <div className="flex justify-center items-center">
                <DottedSpinner className="h-10 w-10 text-gray-400 animate-spin" />
              </div>
            ) : (
              <Formik initialValues={props.formData || initialValues} onSubmit={onSubmit} enableReinitialize>
                {formik => (
                  <Form className="hidden lg:block">
                    {filterForm.map(section => (
                      <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-4">
                        {({ open }) => (
                          <>
                            <h3 className="-my-3 flow-root">
                              <Disclosure.Button className="py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">{section.name}</span>
                                <span className="ml-6 flex items-center">{open ? <MinusSmIcon className="h-5 w-5" aria-hidden="true" /> : <PlusSmIcon className="h-5 w-5" aria-hidden="true" />}</span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-4">
                                <FormControl control="checkbox" label="" name={section.id} options={section.options} submitForm={formik.submitForm} listed />
                                {/* {section.options.map((option, optionIdx) => (
                                  <div key={option.value} className="flex items-center">
                                    <input id={`filter-${section.id}-${optionIdx}`} name={`${section.id}[]`} defaultValue={option.value} type="checkbox" defaultChecked={option.checked} className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500" />
                                    <label htmlFor={`filter-${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600">
                                      {option.label}
                                    </label>
                                  </div>
                                ))} */}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </Form>
                )}
              </Formik>
            )}

            {/* Product grid */}
            <div className="lg:col-span-3">{props.children}</div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default OfferFilters;
