import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormControl from "../../../components/FormUI/FormControl";

function Step_3(props) {
  // const features = [
  //   { _id: "1", title: "E-Ladestation" },
  //   { _id: "2", title: "WLAN" },
  //   { _id: "3", title: "Regionale Produkte" }
  // ];

  const initialValues = {
    gastroSeats: {
      total: "",
      indoor: "",
      outdoor: ""
    },
    covers: "",
    wellnessArea: {
      total: "",
      saunaArea: "",
      sunbeds: "",
      swimmingPoolCapacity: "",
      whirlPoolCapacity: ""
    },
    features: []
  };

  const validationSchema = Yup.object({
    gastroSeats: Yup.object({
      total: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").min(0, "Zahl muss positiv sein"),
      indoor: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").min(0),
      outdoor: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").min(0)
    }),
    covers: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").min(0, "Zahl muss positiv sein"),
    wellnessArea: Yup.object({
      total: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").min(0),
      saunaArea: Yup.number().typeError("Keine gültige Zahl").min(0),
      sunbeds: Yup.number().typeError("Keine gültige Zahl").integer("Nur ganze Zahlen").min(0),
      swimmingPoolCapacity: Yup.number().typeError("Keine gültige Zahl").min(0),
      whirlPoolCapacity: Yup.number().typeError("Keine gültige Zahl").min(0)
    }),
    features: Yup.array()
  });

  function onSubmit(values) {
    const refactorData = { ...values };

    refactorData.gastroSeats.total = refactorData.gastroSeats.total === "" ? 0 : refactorData.gastroSeats.total;
    refactorData.gastroSeats.indoor = refactorData.gastroSeats.indoor === "" ? 0 : refactorData.gastroSeats.indoor;
    refactorData.gastroSeats.outdoor = refactorData.gastroSeats.outdoor === "" ? 0 : refactorData.gastroSeats.outdoor;

    refactorData.covers = refactorData.covers === "" ? 0 : refactorData.covers;

    refactorData.wellnessArea.total = refactorData.wellnessArea.total === "" ? 0 : refactorData.wellnessArea.total;
    refactorData.wellnessArea.saunaArea = refactorData.wellnessArea.saunaArea === "" ? 0 : refactorData.wellnessArea.saunaArea;
    refactorData.wellnessArea.sunbeds = refactorData.wellnessArea.sunbeds === "" ? 0 : refactorData.wellnessArea.sunbeds;
    refactorData.wellnessArea.swimmingPoolCapacity = refactorData.wellnessArea.swimmingPoolCapacity === "" ? 0 : refactorData.wellnessArea.swimmingPoolCapacity;
    refactorData.wellnessArea.whirlPoolCapacity = refactorData.wellnessArea.whirlPoolCapacity === "" ? 0 : refactorData.wellnessArea.whirlPoolCapacity;

    props.storeValues(refactorData);
    nextHandler();
  }

  function nextHandler() {
    props.nextStepHandler();
  }

  function prevHandler(e) {
    e.preventDefault();
    props.prevStepHandler();
  }

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Ausstattung</h3>
            <p className="mt-4 text-xs text-gray-600">* Pflichtfelder</p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Formik initialValues={props.formData || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {formik => (
              <Form>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-4 gap-6">
                      <div className="col-span-4">
                        <FormControl control="number" label="Gastronomie Sitzplätze" name="gastroSeats.total" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="davon innen" name="gastroSeats.indoor" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="input" label="davon außen" name="gastroSeats.outdoor" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="Ausgegebene Gedecke" name="covers" />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="number" label="Gesamtfläche des Wellnessbereichs" name="wellnessArea.total" after="m<sup>2</sup>" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="Grundfläche Saunen" name="wellnessArea.saunaArea" after="m<sup>2</sup>" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="Anzahl Solarien" name="wellnessArea.sunbeds" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="Fassungsvermögen Schwimmingpools" name="wellnessArea.swimmingPoolCapacity" after="m<sup>3</sup>" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="Fassungsvermögen Whirlpools" name="wellnessArea.whirlPoolCapacity" after="m<sup>3</sup>" />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="checkbox" label="Zusätzliche Ausstattung" name="features" options={props.dropdownData.offerFeatures} />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex space-x-2 justify-between md:justify-end">
                    {props.stepNumber > 0 && (
                      <button onClick={prevHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Zurück
                      </button>
                    )}

                    <button disabled={!formik.isValid} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20">
                      Weiter
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Step_3;
