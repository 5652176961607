import React, { useEffect, useState } from "react";
import axios from "axios";
import { CursorClickIcon, CloudIcon, CalculatorIcon, HomeIcon, UsersIcon, BriefcaseIcon, StatusOnlineIcon } from "@heroicons/react/outline";
import { useSelector, useDispatch } from "react-redux";

// Store
import * as authActions from "../../store/actions/auth";
import * as uiActions from "../../store/actions/ui";

// Components
import Page from "../../components/Page";
import Stats from "../../components/Dashboard/Stats";
import { DottedSpinner } from "../../components/Icons/CustomIcons";

function OverviewAdmin(props) {
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const userCount = await axios.post("/getUserCount", { token: user.token }, { cancelToken: fetchRequest.token });
        const companyCount = await axios.post("/getCompanyCount", { token: user.token }, { cancelToken: fetchRequest.token });
        const offerCount = await axios.post("/getOfferCount", { token: user.token }, { cancelToken: fetchRequest.token });
        const co2TotalCount = await axios.post("/getTotalCo2", { token: user.token }, { cancelToken: fetchRequest.token });
        // Durchgeführte Berechnungen
        // Gesamte CO2 Emissionen
        setStats([
          { id: 1, name: "Benutzer", stat: userCount.data, icon: UsersIcon },
          { id: 2, name: "Unternehmen", stat: companyCount.data, icon: BriefcaseIcon },
          { id: 3, name: "Angebote Online", stat: offerCount.data, icon: StatusOnlineIcon },
          {
            id: 4,
            name: "Gesamter Ausgleich",
            stat: parseFloat(co2TotalCount.data).toLocaleString("de-DE"),
            icon: CloudIcon,
            unit: props => (
              <span className="text-sm">
                {" "}
                kg/CO<sub>2</sub>
              </span>
            )
          }
        ]);

        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
          setIsLoading(false);
          dispatch(uiActions.flashMessageDashboard("alert", "Es ist ein Problem aufgetreten", "Bitte versuchen Sie es erneut"));
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  return (
    <Page dashboard title="Übersicht">
      {isLoading ? (
        <div className="flex items-center justify-center p-8">
          <DottedSpinner className="w-10 h-10 text-gray-500 animate-spin" />
        </div>
      ) : (
        <Stats statData={stats} />
      )}
    </Page>
  );
}

export default OverviewAdmin;
