import React from "react";

// Components
import OfferGridItem from "./OfferGridItem";

function OfferGrid({ offers }) {
  return (
    <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-2 lg:gap-x-6 xl:grid-cols-3 xl:gap-x-8">
      {offers.map(offer => (
        <OfferGridItem key={offer._id} offer={offer} />
      ))}
    </div>
  );
}

export default OfferGrid;
