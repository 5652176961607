import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Store
import * as authActions from "../store/actions/auth";
import * as uiActions from "../store/actions/ui";

// Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import FlashMessage from "../components/Shop/FlashMessage";

// Pages
import Offers from "../pages/Shop/Offers";
import OfferDetail from "../pages/Shop/OfferDetail";
import Calculator from "../pages/Shop/Calculator";
import Cart from "../pages/Shop/Cart";
import NotFound from "../pages/NotFound";

function Shop() {
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const messages = useSelector(state => state.ui.ShopFlashMessages);

  return (
    <>
      <Header>
        <FlashMessage messages={messages} />
      </Header>
      {/* Shop Router */}
      <Routes>
        <Route path="offers" element={<Offers />} />
        <Route path="offers/:id" element={<OfferDetail />} />
        {loggedIn && (
          <>
            <Route path="calculator" element={<Calculator />} />
            <Route path="cart" element={<Cart />} />
          </>
        )}

        <Route path="/" element={<Navigate replace to="offers" />} />

        {/* 404 Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Shop;
