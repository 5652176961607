import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormControl from "../../../components/FormUI/FormControl";

function Step_4_6(props) {
  const initialValues = {
    pv: {
      total: "",
      selfConsumption: "",
      feed: ""
    },
    bhkw: {
      total: "",
      selfConsumption: "",
      feed: ""
    },
    solarTherm: {
      total: "",
      selfConsumption: "",
      feed: ""
    }
  };

  const validationSchema = Yup.object({
    pv: Yup.object({
      total: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte"),
      selfConsumption: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte"),
      feed: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte")
    }),
    bhkw: Yup.object({
      total: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte"),
      selfConsumption: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte"),
      feed: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte")
    }),
    solarTherm: Yup.object({
      total: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte"),
      selfConsumption: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte"),
      feed: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte")
    })
  });

  function onSubmit(values) {
    const refactorData = { ...values };

    refactorData.pv.total = refactorData.pv.total === "" ? 0 : refactorData.pv.total;
    refactorData.pv.selfConsumption = refactorData.pv.selfConsumption === "" ? 0 : refactorData.pv.selfConsumption;
    refactorData.pv.feed = refactorData.pv.feed === "" ? 0 : refactorData.pv.feed;

    refactorData.bhkw.total = refactorData.bhkw.total === "" ? 0 : refactorData.bhkw.total;
    refactorData.bhkw.selfConsumption = refactorData.bhkw.selfConsumption === "" ? 0 : refactorData.bhkw.selfConsumption;
    refactorData.bhkw.feed = refactorData.bhkw.feed === "" ? 0 : refactorData.bhkw.feed;

    refactorData.solarTherm.total = refactorData.solarTherm.total === "" ? 0 : refactorData.solarTherm.total;
    refactorData.solarTherm.selfConsumption = refactorData.solarTherm.selfConsumption === "" ? 0 : refactorData.solarTherm.selfConsumption;
    refactorData.solarTherm.feed = refactorData.solarTherm.feed === "" ? 0 : refactorData.solarTherm.feed;

    props.storeValues(refactorData);
    nextHandler();
  }

  function nextHandler() {
    props.nextStepHandler();
  }

  function prevHandler(e) {
    e.preventDefault();
    props.prevStepHandler();
  }

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Stoff- und Energieströme</h3>
            <p className="mt-4 text-md text-gray-600">Erneuerbare Energien Eigenproduktion</p>
            <p className="mt-4 text-xs text-gray-600">* Pflichtfelder</p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Formik initialValues={props.formData || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {formik => (
              <Form>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-4 gap-6">
                      <div className="col-span-4">
                        <FormControl control="number" label="Photovoltaik (PV)" name="pv.total" after="kWh/Jahr" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="davon Eigenverbrauch" name="pv.selfConsumption" after="kWh/Jahr" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="davon Einspeisung oder Weitergabe" name="pv.feed" after="kWh/Jahr" />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="number" label="Blockheizkraftwerk (BHKW)" name="bhkw.total" after="kWh/Jahr" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="davon Eigenverbrauch" name="bhkw.selfConsumption" after="kWh/Jahr" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="davon Einspeisung oder Weitergabe" name="bhkw.feed" after="kWh/Jahr" />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="number" label="Solarthermie" name="solarTherm.total" after="kWh/Jahr" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="davon Eigenverbrauch" name="solarTherm.selfConsumption" after="kWh/Jahr" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="number" label="davon Einspeisung oder Weitergabe" name="solarTherm.feed" after="kWh/Jahr" />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex space-x-2 justify-between md:justify-end">
                    {props.stepNumber > 0 && (
                      <button onClick={prevHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Zurück
                      </button>
                    )}

                    <button disabled={!formik.isValid} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20">
                      Weiter
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Step_4_6;
