import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import FormControl from "../../../components/FormUI/FormControl";

// Components
import LightInfoBox from "../../../components/LightInfoBox";

function Step_6_1(props) {
  const initialValues = {
    hasCo2Certificate: false,
    co2CertificateFile: "",
    periodicCompensation: "",
    singleCompensation: ""
  };

  const validationSchema = Yup.object({
    hasCo2Certificate: Yup.boolean(),
    co2CertificateFile: Yup.mixed(),
    periodicCompensation: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte"),
    singleCompensation: Yup.number().typeError("Keine gültige Zahl").min(0, "Nur positive Werte")
  });

  function onSubmit(values) {
    const refactorData = { ...values };

    refactorData.periodicCompensation = refactorData.periodicCompensation === "" ? 0 : refactorData.periodicCompensation;
    refactorData.singleCompensation = refactorData.singleCompensation === "" ? 0 : refactorData.singleCompensation;

    props.storeValues(refactorData);
    nextHandler();
  }

  function nextHandler() {
    props.nextStepHandler();
  }

  function prevHandler(e) {
    e.preventDefault();
    props.prevStepHandler();
  }

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Engagement - Klima</h3>
            {/* <p className="mt-4 text-xs text-gray-600">* Pflichtfelder</p> */}
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Formik initialValues={props.formData || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {formik => (
              <Form>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-4 gap-6">
                      <div className="col-span-4">
                        <FormControl control="checkbox" label="Liegt eine Berechnung der CO<sub>2</sub>e-Emissionen für Ihren Betrieb vor?" name="hasCo2Certificate" simple />
                      </div>
                      {formik.values.hasCo2Certificate && (
                        <>
                          <div className="col-span-4">
                            <FormControl control="file" label="Bitte wählen Sie Ihre Berechnung aus" name="co2CertificateFile" fileTypes="application/pdf" single formik={formik} />
                          </div>
                          <div className="col-span-4 -mt-2">
                            <LightInfoBox info="Bitte fügen Sie Ihre Berechnung zu <strong>einer PDF-Datei</strong> zusammen." />
                          </div>
                        </>
                      )}
                      <div className="col-span-4">
                        <FormControl control="number" label="Bereits durchgeführte periodische CO<sub>2</sub>e-Kompensationen" name="periodicCompensation" after="t/Jahr" />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="number" label="Bereits durchgeführte einmalige CO<sub>2</sub>e-Kompensationen (in Summe)" name="singleCompensation" after="t/Jahr" />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex space-x-2 justify-between md:justify-end">
                    {props.stepNumber > 0 && (
                      <button onClick={prevHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Zurück
                      </button>
                    )}

                    <button disabled={!formik.isValid} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20">
                      Weiter
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Step_6_1;
