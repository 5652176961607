import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormControl from "../../../components/FormUI/FormControl";

function Step_0(props) {
  const initialValues = {
    coverImg: "",
    offerName: "",
    companyName: "",
    street: "",
    postalCode: "",
    companyEmail: "",
    socialMedia: {
      web: ""
    },
    activityType: "",
    shortDescription: "",
    description: ""
  };

  const validationSchema = Yup.object({
    coverImg: Yup.mixed(),
    offerName: Yup.string().required("Pflichtfeld"),
    companyName: Yup.string().required("Pflichtfeld"),
    street: Yup.string().required("Pflichtfeld"),
    postalCode: Yup.string().required("Pflichtfeld"),
    companyEmail: Yup.string().email("Keine gültige Email Adresse").required("Pflichtfeld"),
    socialMedia: Yup.object({
      web: Yup.string()
    }),
    activityType: Yup.string().required("Pflichtfeld"),
    shortDescription: Yup.string().max(200, "Maximal 200 Zeichen").required("Pflichtfeld"),
    description: Yup.string().required("Pflichtfeld")
  });

  function onSubmit(values) {
    props.storeValues(values);
    nextHandler();
  }

  function nextHandler() {
    props.nextStepHandler();
  }

  function prevHandler(e) {
    e.preventDefault();
    props.prevStepHandler();
  }

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Kontaktdaten</h3>
            <p className="mt-1 text-sm text-gray-600">Bitte laden Sie nur Bilder hoch, deren Rechte Sie besitzen. Einige dieser Informationen werden öffentlich sichtbar sein.</p>
            <p className="mt-1 text-sm text-gray-600">Die Beschreibung Ihres Angebots unterstützt die Auszeichnungssprache Markdown. Somit sind Sie in der Lage einfache Formatierungen anzuwenden.</p>

            <p className="mt-1 text-sm text-gray-600">
              Informationen zur Verwendung finden Sie{" "}
              <a href="https://www.markdownguide.org/cheat-sheet/" rel="noreferrer" target="_blank" className="font-semibold text-indigo-600">
                hier
              </a>
              .
            </p>
            <p className="mt-4 text-xs text-gray-600">* Pflichtfelder</p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Formik initialValues={props.formData || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {formik => (
              <Form>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-4 gap-6">
                      <div className="col-span-4">
                        <FormControl control="coverimg" name="coverImg" formik={formik} />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="input" type="text" label="Angebotsname *" name="offerName" />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="input" type="text" label="Firma *" name="companyName" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="input" type="text" label="Straße, Hausnummer *" name="street" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="input" type="text" label="Postleitzahl, Ort *" name="postalCode" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="input" type="email" label="Email *" name="companyEmail" />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <FormControl control="input" type="text" label="Webseite" name="socialMedia.web" before="http://" />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="select" label="Art der betrieblichen Tätigkeit *" name="activityType" options={[{ _id: null, title: "" }, ...props.dropdownData.categories]} />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="textarea" label="Übersichtstext *" name="shortDescription" />
                      </div>
                      <div className="col-span-4">
                        <FormControl control="textarea" label="Beschreibung *" name="description" />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex space-x-2 justify-between md:justify-end">
                    {props.stepNumber > 0 && (
                      <button onClick={prevHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Zurück
                      </button>
                    )}

                    <button disabled={!formik.isValid} type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-20">
                      Weiter
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Step_0;
