import React from "react";
import { Link } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/solid";
import { offerStatusTypes } from "../../utils";

// Components
import Badge from "../Badge";

function DashboardOffersRegistryItem(props) {
  const { _id, offerName, companyName } = props.registry;
  let statusBadge = null;

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{offerName}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{companyName}</td>
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <Link to={_id} className="text-indigo-600 hover:text-indigo-900 hidden sm:block">
          <span className="sr-only">{offerName}, </span>Anzeigen
        </Link>
        <Link to={_id} className="sm:hidden inline-flex items-center p-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-transparent  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span className="sr-only">{offerName}, anzeigen </span>
          <PencilIcon className="h-5 w-5 text-indigo-600" />
        </Link>
      </td>
    </tr>
  );
}

export default DashboardOffersRegistryItem;
