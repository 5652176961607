import React, { useEffect, useState, Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon, ShoppingBagIcon } from "@heroicons/react/outline";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// Store
import * as authActions from "../store/actions/auth";
import * as uiActions from "../store/actions/ui";

// Components
import Avatar from "../components/Avatar";
import { DottedSpinner } from "../components/Icons/CustomIcons";

const navigation = [
  { name: "Angebote", href: "offers", current: true },
  { name: "Über das Projekt", href: "about", current: false }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header(props) {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const user = useSelector(state => state.auth.user);
  const cartItems = useSelector(state => state.ui.cartItems);

  const [userNavigation, setUserNavigation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user.role < 20) {
      setUserNavigation([
        { name: "Dashboard", href: "dashboard" },
        // { name: "Verlauf", href: "historie" },
        { name: "Abmelden", href: "logout" }
      ]);
    } else {
      setUserNavigation([
        // { name: "Verlauf", href: "historie" },
        { name: "Abmelden", href: "logout" }
      ]);
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      const fetchRequest = axios.CancelToken.source();
      let requestAborted = false;

      async function fetchData() {
        try {
          const response = await axios.post(`/cart/count`, { token: user.token }, { cancelToken: fetchRequest.token });
          dispatch(uiActions.setCartItems(response.data));
          setIsLoading(false);
        } catch (e) {
          if (!requestAborted) {
            console.log("There was an error");
          }
        }
      }
      fetchData();

      return () => {
        requestAborted = true;
        fetchRequest.cancel();
      };
    }
  }, []);

  function handleLogout() {
    dispatch(authActions.logout());
    dispatch(uiActions.flashMessageShop("success", "Erfolgreich abgemeldet"));
  }

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-white border-b border-gray-200">
        {({ open }) => (
          <>
            {/* Top navigation */}
            {!loggedIn && (
              <div className="bg-gray-900">
                <div className="max-w-7xl mx-auto h-8 px-4 flex items-center justify-end sm:px-6 lg:px-8">
                  <div className="flex items-center space-x-6 text-sm font-light text-white">
                    <Link to="login" className="hover:text-gray-100">
                      Anmelden
                    </Link>
                    <Link to="register" className="hover:text-gray-100">
                      Registrieren
                    </Link>
                  </div>
                </div>
              </div>
            )}

            {props.children}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/">
                    <img className="block lg:hidden h-8 w-auto" src="https://zenapa.de/wp-content/uploads/2019/02/zenapa_original2.png" alt="Zenapa" />
                    <img className="hidden lg:block h-8 w-auto" src="https://zenapa.de/wp-content/uploads/2019/02/zenapa_original2.png" alt="Zenapa" />
                  </Link>
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map(item => (
                    <NavLink key={item.name} to={item.href} className={link => classNames(link.isActive ? "border-indigo-500 text-gray-900" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700", "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium")}>
                      {item.name}
                    </NavLink>
                  ))}
                </div>
                {loggedIn ? (
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    {/* Cart */}
                    <div className="ml-4 flow-root lg:ml-8">
                      <Link to="cart" className="group -m-2 p-2 flex items-center">
                        <ShoppingBagIcon className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                        <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{cartItems}</span>
                        <span className="sr-only">items in cart, view bag</span>
                      </Link>
                    </div>

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      <div className="h-8 w-8">
                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="sr-only">Open user menu</span>
                          <Avatar size={8} username={user.firstname + " " + user.lastname} />
                        </Menu.Button>
                      </div>
                      <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map(item => (
                            <Fragment key={item.name}>
                              {item.href === "logout" ? (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <button onClick={handleLogout} className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700 w-full text-left")}>
                                      {item.name}
                                    </button>
                                  )}
                                </Menu.Item>
                              ) : (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <Link to={item.href} className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}>
                                      {item.name}
                                    </Link>
                                  )}
                                </Menu.Item>
                              )}
                            </Fragment>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                ) : (
                  <div />
                )}

                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? <XIcon className="block h-6 w-6" aria-hidden="true" /> : <MenuIcon className="block h-6 w-6" aria-hidden="true" />}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map(item => (
                  <Disclosure.Button key={item.name} as={Fragment}>
                    <NavLink to={item.href} className={link => classNames(link.isActive ? "bg-indigo-50 border-indigo-500 text-indigo-700" : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800", "block pl-3 pr-4 py-2 border-l-4 text-base font-medium")}>
                      {item.name}
                    </NavLink>
                  </Disclosure.Button>
                ))}
              </div>
              {/* Only if LoggedIn */}
              {loggedIn && (
                <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <Avatar size={10} username={user.firstname + " " + user.lastname} />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">{user.firstname + " " + user.lastname}</div>
                      <div className="text-sm font-medium text-gray-500">{user.email}</div>
                    </div>
                    {/* Cart */}
                    <div className="ml-auto flow-root">
                      <Link to="cart" className="group -m-2 p-2 flex items-center">
                        <ShoppingBagIcon className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                        <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{cartItems}</span>
                        <span className="sr-only">items in cart, view bag</span>
                      </Link>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1">
                    {userNavigation.map(item => (
                      <Fragment key={item.name}>
                        {item.href === "logout" ? (
                          <Disclosure.Button key={item.name} as={Fragment} className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                            <button onClick={handleLogout}>{item.name}</button>
                          </Disclosure.Button>
                        ) : (
                          <Disclosure.Button key={item.name} as={Link} to={item.href} className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                            {item.name}
                          </Disclosure.Button>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}

export default Header;
