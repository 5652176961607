import { Fragment, useState, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Routes, Route, Navigate, NavLink, Link, useNavigate } from "react-router-dom";
import { BriefcaseIcon, HomeIcon, InboxIcon, MenuAlt2Icon, UsersIcon, XIcon, CollectionIcon } from "@heroicons/react/outline";
// import { SearchIcon } from "@heroicons/react/solid";
import { useSelector, useDispatch } from "react-redux";

// Store
import * as authActions from "../store/actions/auth";
import * as uiActions from "../store/actions/ui";

// Components
import Avatar from "../components/Avatar";
import FlashMessage from "../components/Shop/FlashMessage";

// Pages
import OverviewCompany from "../pages/Dashboard/OverviewCompany";
import OverviewAdmin from "../pages/Dashboard/OverviewAdmin";
import DashboardOfferDetail from "../pages/Dashboard/DashboardOfferDetail";
import DashboardOffers from "../pages/Dashboard/DashboardOffers";
import DashboardOfferAddForm from "../pages/Dashboard/DashboardOfferAddForm";
import Registry from "../pages/Dashboard/Registry/Registry";
import OfferRegistry from "../pages/Dashboard/OfferRegistry/OfferRegistry";
import OfferRegistryDetail from "../pages/Dashboard/OfferRegistry/OfferRegistryDetail";
import Companies from "../pages/Dashboard/Companies/Companies";
import OfferSubmitInfo from "../pages/InfoScreen";

const userNavigation = [
  { name: "Zurück zur Startseite", href: "/" },
  { name: "Abmelden", href: "logout" }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState([]);
  const user = useSelector(state => state.auth.user);
  const messages = useSelector(state => state.ui.DashboardFlashMessages);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(uiActions.resetFlashmessage());

    if (user.role < 20 && user.role >= 10) {
      setNavigation([
        { name: "Übersicht", href: "overview", icon: HomeIcon, current: true },
        { name: "Angebote", href: "offers", icon: CollectionIcon, current: false }
      ]);
    } else if (user.role < 10) {
      setNavigation([
        { name: "Übersicht", href: "overview", icon: HomeIcon, current: true },
        { name: "Unternehmen", href: "companies", icon: BriefcaseIcon, current: false },
        { name: "Registrierungen", href: "requests-registry", icon: UsersIcon, current: false },
        { name: "Angebotsanfragen", href: "requests-offer", icon: InboxIcon, current: false }
      ]);
    }
  }, []);

  function handleLogout() {
    dispatch(authActions.logout());
    dispatch(uiActions.flashMessageShop("success", "Erfolgreich abgemeldet"));
    navigate("/", { replace: true });
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full">
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button type="button" className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img className="h-8 w-auto" src="https://zenapa.de/wp-content/uploads/2019/02/zenapa_original2.png" alt="Zenapa" />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {navigation.map(item => (
                      <NavLink key={item.name} to={item.href} className={link => classNames(link.isActive ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white", "group flex items-center px-2 py-2 text-base font-medium rounded-md")}>
                        <item.icon className={classNames(item.current ? "text-gray-300" : "text-gray-400 group-hover:text-gray-300", "mr-4 flex-shrink-0 h-6 w-6")} aria-hidden="true" />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
            <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
              <img className="h-8 w-auto" src="https://zenapa.de/wp-content/uploads/2019/02/zenapa_original2.png" alt="Zenapa" />
            </div>
            <div className="flex-1 flex flex-col overflow-y-auto">
              <nav className="flex-1 px-2 py-4 space-y-1">
                {navigation.map(item => (
                  <NavLink key={item.name} to={item.href} className={link => classNames(link.isActive ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white", "group flex items-center px-2 py-2 text-sm font-medium rounded-md")}>
                    <item.icon className={classNames(item.current ? "text-gray-300" : "text-gray-400 group-hover:text-gray-300", "mr-3 flex-shrink-0 h-6 w-6")} aria-hidden="true" />
                    {item.name}
                  </NavLink>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col">
          <FlashMessage messages={messages} />
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button type="button" className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-end">
              {/* <div className="flex-1 flex">
                <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input id="search-field" className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm" placeholder="Search" type="search" name="search" />
                  </div>
                </form>
              </div> */}
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <Avatar size={8} username={user.firstname + " " + user.lastname} />
                    </Menu.Button>
                  </div>
                  <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map(item => (
                        <Fragment key={item.name}>
                          {item.href === "logout" ? (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <button onClick={handleLogout} className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700 w-full text-left")}>
                                  {item.name}
                                </button>
                              )}
                            </Menu.Item>
                          ) : (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link to={item.href} className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}>
                                  {item.name}
                                </Link>
                              )}
                            </Menu.Item>
                          )}
                        </Fragment>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1">
            {/* Dashboard Router */}
            <Routes>
              <Route path="overview" element={user.role < 10 ? <OverviewAdmin /> : <OverviewCompany />} />

              <Route path="offers" element={<DashboardOffers />} />
              <Route path="offers/submit-confirm" element={<OfferSubmitInfo pageTitle="Angebot eingereicht" infoText="Ihre Angebotsanfrage ist bei uns eingegangen und wird nun überprüft. Bitte haben Sie Geduld." link={{ to: "/dashboard/offers", text: "Zurück zur Übersicht" }} />} />
              <Route path="offers/add" element={<DashboardOfferAddForm />} />
              <Route path="offers/:id" element={<DashboardOfferDetail />} />

              <Route path="requests-registry" element={<Registry />} />
              <Route path="requests-offer" element={<OfferRegistry />} />
              <Route path="requests-offer/:id" element={<OfferRegistryDetail />} />
              <Route path="companies" element={<Companies />} />

              <Route path="/" element={<Navigate replace to="overview" />} />
            </Routes>
          </main>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
